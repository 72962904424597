import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import useFetch from "../../src/hooks/useFetch";
import {getImageUrl} from "../services/util";
import PropTypes from "prop-types";

function SEOMetaTags({apiId, filters}) {
    const url = `${process.env.REACT_APP_API_PATH}${apiId}?populate[0]=SEOMetaTags&populate[1]=SEOMetaTags.OGImage` + filters;
    const {response, error, isLoading} = useFetch(url);
    const [metaData, setMetaData] = useState(null);
    const {id} = useParams();

    useEffect(() => {
        if (response?.data) {
            const seoInfo = filters !== "" ? response.data[0].attributes.SEOMetaTags : response.data.attributes.SEOMetaTags;
            if (seoInfo) {
                let image = null;
                if (seoInfo.OGImage && seoInfo.OGImage.data) {
                    image = seoInfo.OGImage.data.attributes.url;
                }

                setMetaData({
                    meta_title: seoInfo.MetaTitle,
                    meta_description: seoInfo.MetaDescription,
                    meta_author: seoInfo.MetaAuthor ?? null,
                    og_title: seoInfo.OGTitle ?? null,
                    og_description: seoInfo.OGDescription ?? null,
                    og_image: image,
                    og_url: seoInfo.OGURL ?? null,
                    og_type: seoInfo.OGType ?? null,
                    og_site_name: seoInfo.OGSiteName ?? null,
                    og_publisher: seoInfo.OGPublisher ?? null,
                    og_published_time: seoInfo.OGPublisherTime ?? null,
                    og_modified_time: seoInfo.OGPublisherModified ?? null,
                });
            }
        }
    }, [filters, response, id]);

    if (isLoading || error || !metaData) return null;

    return (
        <Helmet>
            {
                metaData.meta_title ? <title>{metaData.meta_title}</title> :
                    <title>{"My IT Support USA"}</title>
            }

            <meta name="description" content={metaData.meta_description}/>

            {
                metaData.meta_author ? <meta name="author" content={metaData.meta_author}/> : null
            }

            <meta property="og:locale" content="en_US"/>

            {
                metaData.og_title ? <meta property="og:title" content={metaData.og_title}/> : null
            }

            {
                metaData.og_description ? <meta property="og:description" content={metaData.og_description}/> : null
            }

            {
                metaData.og_url ? <meta property="og:url" content={metaData.og_url}/> : null
            }

            {
                metaData.og_image ?
                    <meta property="og:image" content={getImageUrl(metaData.og_image)}/> :
                    <meta property="og:image" content={process.env.PUBLIC_URL + '/images/misu-logo.png'}/>
            }

            {
                metaData.og_site_name ?
                    <meta property="og:site_name" content={metaData.og_site_name}/> : null
            }

            {
                metaData.og_type ? <meta property="og:type" content={metaData.og_type}/> : null
            }

            {
                metaData.og_publisher ? <meta property="article:publisher" content={metaData.og_publisher}/> : null
            }

            {
                metaData.og_published_time ?
                    <meta property="article:published_time" content={metaData.og_published_time}/> : null
            }

            {
                metaData.og_modified_time ?
                    <meta property="article:modified_time" content={metaData.og_modified_time}/> : null
            }
        </Helmet>
    );
}

SEOMetaTags.propTypes = {
    apiId: PropTypes.string.isRequired,
    filters: PropTypes.string,
};

export default SEOMetaTags;
