import useFetch from "../../hooks/useFetch";
import PropTypes from "prop-types";

function ShopCategoriesComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=FooterItems&populate[1]=FooterItems.menu_dropdowns&populate[2]=FooterItems.menu_dropdowns.MenuLinks";
    const {response} = useFetch(url);

    const categories = [];
    createResponse(response);

    return (
        <div className="shop-categories-container mb-5">
            {
                categories ?
                    categories.map((item) => (
                        <div key={item.id}>
                            <h4 className="mb-3">
                                {item.title}
                            </h4>

                            {
                                item.categories.map((category) => (
                                    category.is_active ?
                                        <p key={category.id} className="py-1 shop-categories">
                                            <a href={"/solutions/" + category.slug}>
                                                {category.name}
                                            </a>
                                        </p> : null
                                ))
                            }
                        </div>
                    ))
                    : ""
            }
        </div>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.FooterItems) {
                data.FooterItems.forEach(function (item) {
                    const menuItems = [];
                    const categoryItems = item.menu_dropdowns ? item.menu_dropdowns.data : [];
                    categoryItems.forEach(function (categoryItem) {
                        const category = categoryItem.attributes;
                        menuItems.push({
                            id: categoryItem.id,
                            name: category.name,
                            slug: category.slug,
                            is_active: category.is_active,
                        })
                    })

                    categories.push({
                        id: item.id,
                        title: item.title,
                        categories: menuItems
                    })
                })
            }
        }
    }
}

ShopCategoriesComponent.propTypes = {
    apiId: PropTypes.string.isRequired
};

export default ShopCategoriesComponent;