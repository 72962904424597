import useFetch from "../../hooks/useFetch";

function AddressInformationComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=AddressInformation";
    const {response} = useFetch(url);

    const addressInformation = [];
    createResponse(response);

    return (
        addressInformation ?
            addressInformation.map((item) => (
                <div className="col-lg-3 col-md-12 col-sm-12 col-12 position-relative" key={item.id}>
                    <div className="separator"></div>

                    <div className="information-container py-sm-2 py-md-2">
                        <p dangerouslySetInnerHTML={{__html: item.address}}></p>
                    </div>
                </div>
            )) : ""
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.AddressInformation) {
                const companyAddressInformation = data.AddressInformation;
                addressInformation.push({
                    id: companyAddressInformation.id,
                    address: companyAddressInformation.addressLines,
                })
            }
        }
    }
}

export default AddressInformationComponent;