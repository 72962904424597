import useFetch from "../../hooks/useFetch";
import "../privacy-policy/Policy.css"
import PolicyAccordion from "../../components/PolicyAccordion";
import React, {useEffect, useMemo, useState} from "react";
import SEOMetaTags from "../../components/SEOMetaTags";

function TermsAndConditions() {
    const url = process.env.REACT_APP_API_PATH + "/terms-and-condition-policy?populate[0]=terms_and_conditions";
    const {response} = useFetch(url);

    const [conditionContent, setConditionContent] = useState(null);
    const [policyInformation, setPolicyInformation] = useState([]);

    const memoizedTermsAndConditions = useMemo(() => {
        return createResponse(response);
    }, [response]);

    useEffect(() => {
        if (memoizedTermsAndConditions) {
            setConditionContent(memoizedTermsAndConditions.mainContent);
            setPolicyInformation(memoizedTermsAndConditions.termsConditions);
        }
    }, [memoizedTermsAndConditions]);

    return (
        <section className="privacy-policy-main-container">
            <SEOMetaTags apiId={"/terms-and-condition-policy"} filters=""/>

            <div className="container">
                <div className="container-fluid section-spacing">
                    <div className="legal-heading-container text-center">
                        <h1>
                            Terms & Conditions
                        </h1>
                    </div>

                    <div className="legal-description-container"
                         dangerouslySetInnerHTML={{__html: conditionContent}}></div>

                    {
                        policyInformation ? <PolicyAccordion policyInformation={policyInformation}/> : null
                    }
                </div>
            </div>
        </section>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const information = response.data ? response.data.attributes : [];
            if (information) {
                const mainContent = information.content;

                let termsConditions = [];
                if (information.terms_and_conditions && information.terms_and_conditions.data) {
                    termsConditions = information.terms_and_conditions.data.map((condition) => {
                        const policyItem = condition.attributes ? condition.attributes : [];
                        return {
                            id: condition.id,
                            heading: policyItem.heading,
                            content: policyItem.content,
                        }
                    })
                }

                return {mainContent, termsConditions}
            }
        }
    }
}

export default TermsAndConditions;