import useFetch from "../../../hooks/useFetch";
import PropTypes from "prop-types";

function FilterCategories({apiId, priceFilter, categoryFilter, slug}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=GroupCategory&populate[1]=GroupCategory.group_categories";
    const {response} = useFetch(url);

    let heading = null;
    let categories = [];

    createResponse(response);

    return (
        categories ?
            <div className="dropdown select-component">
                <button className="dropdown-toggle select-toggle" type="button" id="categoriesFilter"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {categoryFilter ? (categoryFilter.replace("-", " ")) : heading}
                </button>

                <div className="dropdown-menu select-menu" aria-labelledby="categoriesFilter">
                    {
                        categories.map((item) => (
                            <a key={item.id} className="dropdown-item"
                               href={slug + "?category=" + item.slug + (priceFilter ? "&price=" + priceFilter : "")}>
                                {item.name}
                            </a>
                        ))
                    }
                </div>
            </div> : null
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.GroupCategory) {
                heading = data.GroupCategory.heading;

                const categoryItems = data.GroupCategory.group_categories ? data.GroupCategory.group_categories.data : [];
                categoryItems.forEach(function (categoryItem) {
                    const category = categoryItem.attributes;
                    categories.push({
                        id: categoryItem.id,
                        name: category.name,
                        slug: category.slug,
                    })
                });
            }
        }
    }
}

FilterCategories.propTypes = {
    apiId: PropTypes.string.isRequired,
    priceFilter: PropTypes.string,
    categoryFilter: PropTypes.string,
    slug: PropTypes.string.isRequired,
};

export default FilterCategories;