// utils.js

const apiUrl = process.env.REACT_APP_API_PUBLIC_PATH; // or process.env.REACT_APP_API_PATH if that contains your base URL

export const getImageUrl = (imgURL) => {
    return imgURL.startsWith('/uploads/') ? `${apiUrl}${imgURL}` : imgURL;
};

export const updateCartCount = () => {
    let products = localStorage.getItem("products");
    let cartCount = JSON.parse(products);

    let cartIcon = document.getElementById("buy-more");
    if (cartIcon) {
        if (cartCount && cartCount.length > 0) {
            let count = cartCount.length;
            cartIcon.setAttribute("data-badge", count)
        } else {
            cartIcon.removeAttribute("data-badge");
        }
    }
}

export const formatDate = (dateString) => {
    const options = {year: "numeric", month: "long", day: "numeric"};
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
}