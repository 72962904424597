import useFetch from "../../hooks/useFetch";
import "./Policy.css"
import PolicyAccordion from "../../components/PolicyAccordion";
import React, {useEffect, useMemo, useState} from "react";
import SEOMetaTags from "../../components/SEOMetaTags";

function PrivacyPolicy() {
    const url = process.env.REACT_APP_API_PATH + "/privacy-policies/?sort=id:ASC";
    const {response} = useFetch(url);

    const [policyInformation, setPolicyInformation] = useState([]);

    const memoizedPrivacyPolicy = useMemo(() => {
        return createResponse(response);
    }, [response]);

    // Update state with memoized data when response changes
    useEffect(() => {
        if (memoizedPrivacyPolicy) {
            setPolicyInformation(memoizedPrivacyPolicy.privacyPolicy);
        }
    }, [memoizedPrivacyPolicy]);

    return (
        <section className="privacy-policy-main-container">
            <SEOMetaTags apiId={"/privacy"} filters=""/>

            <div className="container">
                <div className="container-fluid section-spacing">
                    <div className="legal-heading-container text-center">
                        <h1>
                            Privacy Policy
                        </h1>
                    </div>

                    {
                        policyInformation ? <PolicyAccordion policyInformation={policyInformation} /> : null
                    }
                </div>
            </div>
        </section>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];
            if (data) {
                const privacyPolicy = data.map((policy) => {
                    const policyItem = policy.attributes ? policy.attributes : [];
                    return {
                        id: policy.id,
                        heading: policyItem.heading,
                        content: policyItem.content,
                    }
                });

                return {privacyPolicy};
            }
        }
    }
}

export default PrivacyPolicy;