import useFetch from "../../hooks/useFetch";
import { getImageUrl } from "../../services/util";

function SocialMediaLinksComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "?populate[0]=SocialMediaLinks&populate[1]=SocialMediaLinks.icon";
    const {response} = useFetch(url);

    const socials = [];
    createResponse(response);

    return (
        <div className="social-media-links-main-container content-middle">
            {
                socials ?
                    socials.map((socialMediaLink, i) => (
                        <div className="social-container" key={"social-media-link-" + i}>
                            <a href={socialMediaLink.url} target={socialMediaLink.open_new_window ? "_blank" : ""}
                               rel="noreferrer" data-testid={"link-" + socialMediaLink.name} className="social-icons">
                                <img loading="lazy" src={ getImageUrl(socialMediaLink.icon)}
                                     alt={socialMediaLink.name + "-icon"} className="img-fluid"/>
                            </a>
                        </div>
                    )) : ""
            }
        </div>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.SocialMediaLinks) {
                const socialMediaLinks = data.SocialMediaLinks;
                socialMediaLinks.forEach(function (socialMediaLink) {
                    const iconImage = socialMediaLink.icon.data.attributes;
                    socials.push({
                        id: socialMediaLink.id,
                        icon: iconImage.url,
                        name: socialMediaLink.name,
                        url: socialMediaLink.url,
                        open_new_window: socialMediaLink.open_new_window,
                    })
                })
            }
        }
    }
}

export default SocialMediaLinksComponent;