import React from 'react';
import './App.css';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {AppRouter} from "./pages/routes/AppRouter";
import {BrowserRouter} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';


function App() {
    return (
        <BrowserRouter>
            <AppRouter/>
            <CookieConsent
                location="none" // Ensure no default location
                buttonText="Accept All "
                declineButtonText="Reject All"
                enableDeclineButton
                cookieName="myMISUAppCookieConsent"
                containerClasses="cookie-consent mr-5 ml-5"
                buttonClasses="cookie-consent-button"
                declineButtonClasses="cookie-consent-decline-button"
                expires={150}
                onAccept={() => {
                    // Handle accept action
                    Cookies.set("myMISUAppCookieConsent", "accepted", {expires: 150});
                }}
                onDecline={() => {
                    // Handle reject action
                    Cookies.remove("myMISUAppCookieConsent");
                }}
            >
                <h5><b>We value your privacy</b></h5>

                <p className='mt-4'>We use cookies to enhance your browsing experience, improve content, and analyze our
                    website traffic and data. By clicking "Accept All", you consent to our use of cookies and our
                    Privacy Policy.</p>
            </CookieConsent>
        </BrowserRouter>
    );
}

export default App;
