import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contactus.css';
import useFetch from "../../hooks/useFetch";

function Contactus({ apiId }) {
    const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=Contactus.Header&populate[1]=Contactus.Benefits&populate[2]=Contactus.HappensNext&populate[3]=Contactus.ContactFormImage`;
    const { response } = useFetch(url);
    const contactusdetails = [];
    createResponse(response);

    const formRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://manageditllc.myfreshworks.com/crm/sales/web_forms/8e3a4e0bb67b03fa845e7f1bcf0f978560461d0cc8487a4e945fb22ca63cf43a/form.js';
        script.crossOrigin = 'anonymous';
        script.async = true;

        const currentFormRef = formRef.current;

        if (currentFormRef) {
            currentFormRef.appendChild(script);

            script.onload = () => {
                // Wait for the form to be fully loaded
                setTimeout(() => {
                    const formElement = currentFormRef.querySelector('form');
                    if (formElement) {
                        // Get the checkbox and the text field elements inside the form
                        const checkbox = formElement.querySelector('input[name="contact[custom_field][cf_regular_customer]"]');
                        const textField = formElement.querySelector('input[name="contact[custom_field][cf_company__organization]"]');

                        if (checkbox && textField) {
                            // Initial check to disable the text field
                            if (!checkbox.checked) {
                                textField.disabled = true;
                            }

                            // Add event listener to the checkbox
                            checkbox.addEventListener('change', function () {
                                if (this.checked) {
                                    textField.disabled = false;
                                } else {
                                    textField.disabled = true;
                                }
                            });
                        } else {
                            console.error('Checkbox or TextField not found in the form.');
                        }
                    } else {
                        console.error('Form element not found.');
                    }
                }, 1000); // Adjust the timeout if necessary
            };
        } else {
            console.error('Ref for contact form not found.');
        }

        return () => {
            if (currentFormRef) {
                currentFormRef.removeChild(script);
            }
        };
    }, []);

    return (
        <section>
            <div className="contact-us">
                {contactusdetails.length > 0 ? contactusdetails.map((item, index) => (
                    <div key={item.id || index}>
                        <div className="header-section text-white p-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="breadcrumb d-inline-block p-2">{item.Breadcrumb}</div>
                                        <p className="main-heading" dangerouslySetInnerHTML={{ __html: item.Header }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper container">
                            <div className="row">
                                <div className="col-md-12 info-sections">
                                    <div className="contact-section p-3 mt-3 mb-2">
                                        <p dangerouslySetInnerHTML={{ __html: item.Paragraph }}></p>
                                    </div>
                                    <div className="col-md-12 row list-section mt-5 mb-5">
                                        <h3 dangerouslySetInnerHTML={{ __html: item.BenefitsHeader }}></h3>
                                        <div className='col-md-4'>
                                            <div className="row">
                                                {item.Benefits.map((benefitItem, benefitIndex) => (
                                                    <div className="col-md-6" key={benefitIndex}>
                                                        <div className="d-flex align-items-center mt-2">
                                                            <span className='circle'></span>
                                                            <p className='align-items-center mt-3'>{benefitItem.BenefitsItem}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-12 mt-5 mb-5'>
                                        <h3 dangerouslySetInnerHTML={{ __html: item.NextHeader }}></h3>
                                        <div className="steps-section mt-3">
                                            {item.HappensNext.map((nextItem, nextIndex) => (
                                                <div className="step" key={nextIndex}>
                                                    <div className="step-number"
                                                         dangerouslySetInnerHTML={{ __html: nextItem.Description }}></div>
                                                    <div className="step-description"
                                                         dangerouslySetInnerHTML={{ __html: nextItem.ItemDescription }}></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : null}

                {/* This div will contain the dynamically appended script */}
                <div className="contact-form" ref={formRef}></div>

                {/* Adding resource hints for prefetching */}
                <link rel="dns-prefetch" href="//manageditllc.myfreshworks.com" />
                <link rel="preconnect" href="https://manageditllc.myfreshworks.com" crossOrigin="anonymous" />
            </div>
        </section>
    );

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes.Contactus : [];
            if (data) {
                let Image = data.ContactFormImage ? data.ContactFormImage.data.attributes : "";
                contactusdetails.push({
                    Header: data.Header.Title,
                    Breadcrumb: data.Header.BreadcrumbTitle,
                    Paragraph: data.BodyParagraph,
                    BenefitsHeader: data.Header.BenefitsHeader,
                    NextHeader: data.Header.HappensNextHeader,
                    Benefits: data.Benefits,
                    HappensNext: data.HappensNext,
                    imageItem: Image.url,
                    formTitle: data.ContactFormTitle,
                    id: data.id
                });
            }
        }
    }
}

export default Contactus;
