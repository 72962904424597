import React, {useEffect, useMemo, useState} from "react";
import useFetch from "../../../hooks/useFetch";
import "../Company.css";
import {getImageUrl} from "../../../services/util";

function CompanyTimeLine({apiId}) {
    const url = `${process.env.REACT_APP_API_PATH}${apiId}?populate[0]=TimeLine&populate[1]=TimeLine.TimelineImage`;
    const {response} = useFetch(url);

    const [timelineItems, setTimelineItems] = useState([]);

    const memoizedTimeline = useMemo(() => {
        return createResponse(response);
    }, [response]);

    useEffect(() => {
        if (memoizedTimeline) {
            setTimelineItems(memoizedTimeline.formattedItems);
        }
    }, [memoizedTimeline]);

    return (
        <div className="timeline-container">
            <div className="timeline">
                <ul>
                    {timelineItems.map((item, index) => (
                        <li
                            key={index}
                            className={
                                index % 2 === 0 ? "timeline-item-left" : "timeline-item-right"
                            }>

                            <div className="content">
                                <h2>{item.heading}</h2>
                                <p>{item.description}</p>
                            </div>

                            <div className="time">
                                {item.imageUrl ? (
                                    <img
                                        className="Image"
                                        src={getImageUrl(item.imageUrl)}
                                        alt={item.heading}
                                    />
                                ) : (
                                    <p>No image available</p>
                                )}
                            </div>
                        </li>
                    ))}
                    <div style={{clear: "both"}}></div>
                </ul>
            </div>
        </div>
    );

    function createResponse(response) {
        if (response?.data) {
            const items = response.data.attributes.TimeLine;
            let formattedItems = [];
            if (items) {
                formattedItems = items.map((item) => ({
                    heading: item.Heading,
                    description: item.Description,
                    imageUrl: item.TimelineImage?.data?.attributes?.url || "",
                }));
            }

            return {formattedItems}
        }
    }
}

export default CompanyTimeLine;
