import {useEffect, useState} from "react";
import {getImageUrl, updateCartCount} from "../../../services/util";

function ProductCards({response, price, category}) {
    const products = [];
    const orderLines = localStorage.getItem("products") ? JSON.parse(localStorage.getItem("products")) : [];
    createResponse(response);

    const filteredProducts = products.filter(function (el) {
        return el.price !== null;
    });

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [emptyMessage, setEmptyMessage] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    function handleAddToCartClick(product) {
        createOrderLines(product);

        if (orderLines.length > 0) {
            localStorage.setItem("products", JSON.stringify(orderLines));
            updateCartCount();
        }
    }

    function handleAlertClose() {
        setMessage(null);
        setError(null);
        setShowAlert(false);
    }

    function sortByProperty(array, order) {
        // Default to ascending order if 'order' is not provided or not recognized
        const sortOrder = (order === 'desc') ? -1 : 1;

        // Sort by price based on sortOrder
        return array.sort((a, b) => {
            return sortOrder * (a.price - b.price);
        });
    }

    if (price) {
        sortByProperty(filteredProducts, price);
    }

    useEffect(() => {
        if (filteredProducts.length === 0 && (price || category)) {
            setEmptyMessage("There are no products with the applied filters.")
        } else if (filteredProducts.length === 0) {
            setEmptyMessage("No products available at the moment.")
        }
    }, [filteredProducts, price, category]);

    return (
        <div className="product-card-main-container">
            {showAlert && message && (
                <div className="message-container">
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        {message}
                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                    </div>
                </div>
            )}

            {showAlert && error && (
                <div className="message-container">
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {error}
                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                    </div>
                </div>
            )}

            <div className="row">
                {
                    filteredProducts.length > 0 ? filteredProducts.map((product) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text-center d-inline-grid mb-3"
                             key={"product-card-" + (product.name).trim()}>
                            <div className="card product-card-container position-relative">
                                <div className="card-header border-bottom-0 text-end">
                                    {
                                        product.is_promotion ?
                                            <span className="on-promotion-badge">On Promotion</span> :
                                            <div className="promotion-spacing"></div>
                                    }
                                </div>

                                <div className="card-body product-card-body pt-0 pb-0">
                                    <div className="product-icon-container">
                                        <img loading="lazy" alt={product.alt}
                                             src={getImageUrl(product.icon)}
                                             className="img-fluid"/>
                                    </div>

                                    <div className="product-name-container">
                                        {product.name}
                                    </div>

                                    <div className="product-price-container">
                                        {
                                            !product.has_fixed_price ?
                                                <span
                                                    className="product-price-information-container">Starting at</span> :
                                                <div className="product-price-spacing"></div>
                                        }

                                        {
                                            product.is_promotion ?
                                                <div className="d-inline">
                                                    <span className="on-promotion">{"$" + product.price}</span>
                                                    <span>{"$" + product.promotion_price}</span>
                                                </div> : "$" + product.price
                                        }
                                    </div>

                                    {
                                        product.price_per_indicator ?
                                            <div className="product-inherited-features-container">
                                                {product.price_per_indicator}
                                            </div>
                                            : <div className="product-inherited-features-spacing"></div>
                                    }

                                    <div className="product-features-container"
                                         dangerouslySetInnerHTML={{__html: product.features}}></div>
                                </div>

                                <div className="card-footer product-footer-container">
                                    <div className="add-to-cart-container">
                                        <button className="btn-default btn btn-lg btn-block w-100 add-to-cart"
                                                onClick={() => handleAddToCartClick(product)}>
                                            Buy Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>)) : <div className="col-12 text-center empty-message">
                        {emptyMessage}
                    </div>
                }
            </div>
        </div>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];
            if (data) {
                data.forEach(item => {
                    const productData = item ? item.attributes : [];
                    const groupSolutions = productData.group_solutions.data ? productData.group_solutions.data : [];

                    createProduct(item.id, productData, null, null)

                    if (groupSolutions) {
                        groupSolutions.forEach(solution => {
                            if (solution.attributes.is_active) {
                                createProduct(item.id, productData, solution, groupSolutions)
                            }
                        })
                    }
                });
            }
        }
    }

    function createProduct(productId, product, groupSolution, solutions) {
        const groupSolutionData = groupSolution ? groupSolution.attributes : [];
        const icon = product.icon.data ? product.icon.data.attributes : [];
        const groupCategory = product.group_category && product.group_category.data ? product.group_category.data.attributes : [];
        const category = product.menu_dropdown && product.menu_dropdown.data ? product.menu_dropdown.data.attributes : [];

        products.push({
            id: productId,
            icon: icon.url,
            alt: icon.alternativeText,
            solution_name: product.ProductName,
            name: groupSolutionData.ProductName ? groupSolutionData.ProductName : product.ProductName,
            sku: product.sku,
            sku_code: product.sku_code,
            price: groupSolutionData.Price ? groupSolutionData.Price : product.Price,
            is_promotion: groupSolutionData.IsOnPromotion ? groupSolutionData.IsOnPromotion : product.IsOnPromotion,
            promotion_price: groupSolutionData.PromotionalPrice ? groupSolutionData.PromotionalPrice : product.PromotionalPrice,
            price_per_indicator: product.PricePerIndicator,
            has_fixed_price: product.HasFixedPrice,
            additional_features: product.InheritedFeatures,
            features: groupSolutionData.Features ? groupSolutionData.Features : product.Features,
            min_quantity: groupSolutionData.MinQuantity ? groupSolutionData.MinQuantity : 1,
            group_category: groupCategory.name,
            group_category_slug: groupCategory.slug,
            category: category.name,
            category_slug: category.slug,
            solutions: solutions ?? [],
            is_subscription_based: product.is_subscription_based
        });
    }

    function createOrderLines(product) {
        if (product) {
            let checkIfProductExists = validateBasketItems(orderLines, "product_sku", product.sku);
            if (!checkIfProductExists) {
                orderLines.push({
                    product_id: product.id,
                    product_name: product.solution_name,
                    product_sku: product.sku,
                    product_sku_code: product.sku_code,
                    category: product.category,
                    category_slug: product.category_slug,
                    group_category: product.group_category,
                    group_category_slug: product.group_category_slug,
                    price: product.is_promotion ? product.promotion_price : product.price,
                    line_total: product.min_quantity * (product.is_promotion ? product.promotion_price : product.price),
                    price_per_indicator: product.price_per_indicator,
                    quantity: product.min_quantity,
                    icon: product.icon,
                    alt: product.alt,
                    solutions: product.solutions ?? [],
                    is_subscription_based: product.is_subscription_based
                });

                let message = "Your item has been successfully added to your shopping cart.";
                setMessage(message);
                setShowAlert(true)
            } else {
                let message = "It looks like that product is already in your cart. You can update the quantities in the Shopping Cart if needed.";
                setError(message)
                setShowAlert(true)
            }
        }

        const alertTimer = setTimeout(() => {
            setMessage(null);
            setShowAlert(false);
        }, 5000);

        return () => clearTimeout(alertTimer);
    }

    function validateBasketItems(array, key, value) {
        for (const element of array) {
            if (element[key] === value) {
                return true;
            }
        }
        return false;
    }
}

export default ProductCards