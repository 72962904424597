import PropTypes from "prop-types";
import React from "react";

function ErrorHandling({error}) {
    return (
        <section className="default-error-page section-spacing">
            <div className="container">
                <div className="container-fluid">
                    <div className="error-heading-container text-center mb-5">
                        <h1>
                            {error ? "Error Occurred" : "404"}
                        </h1>
                    </div>

                    <div className="error-description-container text-center">
                        {error || "Sorry, we couldn't find what you were looking for. Please double-check the information and try again!"}
                    </div>
                </div>
            </div>
        </section>
    )
}

ErrorHandling.propTypes = {
    error: PropTypes.string
};

export default ErrorHandling;