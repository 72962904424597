export async function getDownloadAgent(token) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/get/download/agent";
    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json'
        }
    });

    return await response.json();
}

export async function getStripeCustomerPortal(token) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/stripe/get/customer/portal/link";

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.api+json'
        }
    };

    try {
        const response = await fetch(url, options);
        const json = await response.json();

        return json.data;
    } catch (error) {
        console.error(error);
    }
}

export async function validateCustomerEmailAddress(email) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/user/validate";

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.api+json'
        },
        body: JSON.stringify({customer_email_address: email})
    };

    try {
        const response = await fetch(url, options);
        const json = await response.json();
        return json.exists;
    } catch (error) {
        console.error(error);
    }
}

export async function validateCustomerBasket(token) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/customer/has/existing/subscriptions";
    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json'
        }
    });

    return await response.json();
}

export async function getAccountInformation(token) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/customer";
    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    return await response.json();
}

export async function updateAccountInformation(token, response) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/customer";

    const options = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.api+json'
        },
        body: JSON.stringify(response)
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

export async function resetPasswordRequest(token) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/user/request-password-reset";

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.api+json'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

export async function requestAccountRemoval(token) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/user/request-account-delete";

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.api+json'
        }
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

export async function requestEmailAddressUpdate(token, customerName) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/user/request-email-update";

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.api+json'
        },
        body: '{"customer_name":"' + customerName + '"}'
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

export async function getOrderHistory(token) {
    let url = process.env.REACT_APP_API_INTEGRATION_PATH + "/V1/customer/sales/receipts";
    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    return await response.json();
}

export async function getSalesReceiptPdf(token, id) {
    if (!token) {
        console.error("No token provided");
        return null;
    }

    let url = `${process.env.REACT_APP_API_INTEGRATION_PATH}/V1/customer/sales/receipt/pdf/${id}`;

    try {
        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        } else {
            console.error("Failed to fetch PDF:", response.statusText);
            return null;
        }
    } catch (error) {
        console.error("Error fetching PDF:", error);
        return null;
    }
}

