import "../client-portal/Portal.css"

function Dashboard() {
    return (
        <div className="dashboard-main-container section-spacing">
            <div className="container-fluid">
                <h1>Dashboard</h1>

                <div className="dashboard-container section-spacing">
                    <div className="row content-middle dashboard-container-row">
                        <div className="col-12 text-center">
                            More information coming soon
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;