import { useEffect, useState, useRef, useCallback } from 'react';

const cache = {};

const useFetch = (url, options) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const cacheKey = useRef(url);

  const fetchData = useCallback(async () => {
    setLoading(true);

    if (cache[cacheKey.current]) {
      setResponse(cache[cacheKey.current]);
      setLoading(false);
    } else {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        cache[cacheKey.current] = json;
        setResponse(json);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
  }, [url, options]);

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Only include fetchData in the dependency array

  // Expose fetchData function in the return object
  return { loading, error, response, fetchData };
};

export default useFetch;
