import React from 'react';

const PrivateLayout = ({children}) => {
    return (
        <div data-testid={"privateDiv"}>
            
                {children}
               
        </div>
    )
}

export default PrivateLayout;