import useFetch from "../../../hooks/useFetch";
import PropTypes from "prop-types";
import {getImageUrl} from "../../../services/util";

function Automation({apiId, slug}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "?filters[category][slug][$eq]=" + slug + "&populate[0]=automation_banner.banner_desktop&populate[1]=automation_banner.banner_mobile";
    const {response} = useFetch(url);

    let heading = null;
    const automations = [];
    createResponse(response);

    return (
        automations.length > 0 ?
            <div className="product-automation-container section-spacing">
                <div className="container">
                    <div className="container-fluid">
                        {
                            heading ?
                                <div className="information-product-name">
                                    <h2>
                                        {heading}
                                    </h2>
                                </div> : null
                        }

                        <div className="row">
                            {
                                automations ? (
                                    <div className="col-12">
                                        <div className="automation-desktop-image-container">
                                            <img loading="lazy" className="img-fluid" alt={automations[0].alt_desktop}
                                                 src={getImageUrl(automations[0].image_desktop)}/>
                                        </div>

                                        <div className="automation-mobile-image-container">
                                            <img loading="lazy" className="img-fluid" alt={automations[0].alt_mobile}
                                                 src={getImageUrl(automations[0].image_mobile)}/>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div> : null
    );

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];
            if (data) {
                data.forEach(product => {
                    const automation = product.attributes ? product.attributes.automation_banner : [];
                    heading = product.attributes.AutomationHeading;

                    if (automation) {
                        let automationDesktop = automation.banner_desktop?.data ? automation.banner_desktop.data.attributes : "";
                        let automationMobile = automation.banner_mobile?.data ? automation.banner_mobile.data.attributes : "";

                        automations.push({
                            image_desktop: automationDesktop.url ?? null,
                            alt_desktop: automationDesktop.alternativeText ?? null,
                            image_mobile: automationMobile.url ?? null,
                            alt_mobile: automationMobile.alternativeText ?? null,
                        })
                    }
                })
            }
        }
    }
}

Automation.propTypes = {
    apiId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
};

export default Automation;