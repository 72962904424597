import React, { useEffect, useState, useCallback } from 'react';
import useFetch from '../../../hooks/useFetch';
import { getImageUrl } from '../../../services/util';

const Banner = ({ apiId }) => {
  const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=Banner&populate[1]=Banner.bannerImage&populate[2]=Banner.CallToActionButtons`;
  const { response, fetchData } = useFetch(url);

  const [banner, setBanner] = useState(null);
  const [image, setImage] = useState(null);
  const [callToActionButtons, setCallToActionButtons] = useState([]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (response && response.data) {
      const data = response.data.attributes || {};
      if (data.Banner) {
        setBanner({
          id: data.Banner.id,
          heading: data.Banner.heading,
          description: data.Banner.description,
        });

        const bannerImage = data.Banner.bannerImage ? data.Banner.bannerImage.data.attributes : null;
        if (bannerImage) {
          setImage({
            url: bannerImage.url,
            alt: bannerImage.alternativeText,
          });
        }

        if (data.Banner.CallToActionButtons) {
          setCallToActionButtons(data.Banner.CallToActionButtons.map(button => ({
            id: button.id,
            title: button.title,
            slug: button.slug,
            type: button.type,
          })));
        }
      }
    }
  }, [response]);

  const renderBannerContent = useCallback(
    () => (
      <div className="banner-content-container" key={banner.id}>
        <div className="banner-heading-container">
          <h1>{banner.heading}</h1>
          <div className="banner-description">{banner.description}</div>
        </div>
      </div>
    ),
    [banner]
  );

  const renderCallToActionButtons = useCallback(
    () => (
      <div className="call-to-action-button-container">
        {callToActionButtons.map((button) => (
          <a href={button.slug} key={button.id} className={`btn btn-lg me-2 btn-${button.type}`}>
            {button.title}
          </a>
        ))}
      </div>
    ),
    [callToActionButtons]
  );

  const renderBannerImage = useCallback(
    () => (
      <div className="banner-image-container" key={image.url}>
        <img
          src={getImageUrl(image.url)}
          alt={image.alt}
          className="img-fluid"
          width="600" // Replace with actual dimensions
          height="400" // Replace with actual dimensions
        />
      </div>
    ),
    [image]
  );

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {banner ? renderBannerContent() : <p>Loading...</p>}
        {callToActionButtons.length > 0 ? renderCallToActionButtons() : null}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-end">
        {image ? renderBannerImage() : <p></p>}
      </div>
    </div>
  );
};

export default Banner;
