import React, { useState, useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import ShopCategoriesComponent from "./ShopCategoriesComponent";
import CertificationsComponent from "./CertificationsComponent";
import ConsultationComponent from "./ConsultationComponent";
import SocialMediaLinksComponent from "./SocialMediaLinksComponent";
import CompanyInformationComponent from "./CompanyInformationComponent";
import AddressInformationComponent from "./AddressInformationComponent";
import PolicyAndConditionsComponent from "./PolicyAndConditionsComponent";
import CopyrightComponent from "./CopyrightComponent";

const Footer = () => {
    const [showFooter, setShowFooter] = useState(false);
    const apiId = "/footer";

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowFooter(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    if (!showFooter) {
        return null; // Render nothing until the footer is ready
    }

    return (
        <footer data-testid="footer-main-container" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/footer-background.jpg)`
        }}>
            <div data-testid="footer-container" className="container p-4">
                <div className="row mt-4">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-left text-xs-center pb-3">
                        <CertificationsComponent apiId={apiId} />
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-12 col-12 text-left text-xs-center pb-3">
                        <ShopCategoriesComponent apiId={apiId} />

                        <div className="mailing-list-container d-none">
                            <form>
                                <div className="email-input mb-2">
                                    <input type="email" placeholder="Don’t miss out updates" />
                                </div>

                                <div className="policy-confirmation mb-2 d-inline-flex">
                                    <input className="form-check-input me-2" type="checkbox" id="acceptPrivacyPolicy" />
                                    <label className="form-check-label" htmlFor="acceptPrivacyPolicy">
                                        I agree to the Privacy Policy and give my permission to process my personal data
                                        for the purposes specified in the Privacy Policy.
                                    </label>
                                </div>

                                <div className="submit-mailing-request">
                                    <button className="btn btn-default btn-lg btn-block me-2 content-middle">
                                        Join Mailing List <FaArrowRightLong />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-left text-xs-center pb-3">
                        <ConsultationComponent apiId={apiId} />
                    </div>
                </div>
            </div>

            <div className="text-center bg-white">
                <div className="container p-4">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12 text-left">
                            <SocialMediaLinksComponent apiId={apiId} />
                        </div>

                        <CompanyInformationComponent apiId={apiId} />

                        <AddressInformationComponent apiId={apiId} />
                    </div>
                </div>
            </div>

            <div className="text-center p-3 bg-white">
                <div className="container">
                    <div className="row content-middle">
                        <CopyrightComponent apiId={apiId} />

                        <PolicyAndConditionsComponent apiId={apiId} />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
