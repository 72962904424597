import "./Blog.css";
import {getImageUrl} from "../../services/util";
import PropTypes from "prop-types";

function PrimaryBlogComponent({blog}) {

    return (
        <div className="row content-middle">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                <h3 className="blog-main-heading">{blog.heading}</h3>
                <div className="blog-main-description">{blog.summary}</div>

                {
                    blog.button ? <a className="btn-primary-link" href={"blog/" + blog.id}
                                     target={blog.open_view_window ? "_blank" : ""} rel="noopener noreferrer">
                        {blog.button}
                    </a> : null
                }
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                {
                    blog.image ?
                        <img className="img-fluid PrimaryBlogImage" alt={blog.alt}
                             src={getImageUrl(blog.image)}/> : null
                }
            </div>
        </div>
    );
}

PrimaryBlogComponent.propTypes = {
    blog: PropTypes.object.isRequired
};

export default PrimaryBlogComponent;
