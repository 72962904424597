import useFetch from "../../hooks/useFetch";
import { getImageUrl } from "../../services/util";

function CertificationsComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=CertificationImages";
    const {response} = useFetch(url);

    const certifications = [];
    createResponse(response);

    return (
        <div className="certifications-main-container">
            {
                certifications ?
                    certifications.map((item) => (
                        <div key={item.name} className="certification-container mt-4">
                            <img loading="lazy" className="certification-image img-fluid" alt={item.alt}
                                 src={getImageUrl(item.imagePath)}/>
                        </div>
                    ))
                    : ""
            }
        </div>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.CertificationImages) {
                const images = data.CertificationImages && data.CertificationImages.data ? data.CertificationImages.data : [];
                images.forEach(function (image) {
                    const imageItems = image.attributes;
                    certifications.push({
                        name: imageItems.name,
                        alt: imageItems.alternativeText,
                        imagePath: imageItems.url,
                    })
                })
            }
        }
    }
}

export default CertificationsComponent;