import {FaRegCopyright} from "react-icons/fa6";
import useFetch from "../../hooks/useFetch";

function CopyrightComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=Copyright";
    const {response} = useFetch(url);

    const information = [];
    createResponse(response);

    return (
        information ?
            information.map((item) => (
                <div className="col-lg-4 col-md-12 col-sm-12 col-12 position-relative" key={item.id}>
                    <div className="information-container py-sm-2 py-md-2 content-middle">
                        <FaRegCopyright/>
                        <a href="/" className="px-1">{item.copyright}</a>
                    </div>
                </div>
            )) : ""
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.Copyright) {
                const copyrightInformation = data.Copyright;
                information.push({
                    id: copyrightInformation.id,
                    copyright: copyrightInformation.copyright
                });
            }
        }
    }
}

export default CopyrightComponent;