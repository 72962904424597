import React from 'react';
import Banner from "./components/Banner";
import Services from "./components/Services";
import Partners from "./components/Partners";
import Solutions from "./components/Solutions";
import Industries from "./components/Industires"; // Adjusted import path
import Vendors from "./components/Vendors";
import Contactus from "../contactus/Contactus";
import useFetch from "../../hooks/useFetch"; // Moved useFetch.js into src/hooks
import LoadingComponent from '../../components/LoadingComponent';
import SEOMetaTags from "../../components/SEOMetaTags";

function Home() {
    const apiId = "/home";

    // Custom hook for fetching data
    const { response: homeData, loading, error } = useFetch(`${process.env.REACT_APP_API_PATH}${apiId}/`);

    if (loading) return <LoadingComponent />;
    if (error) return <p>Error loading home data: {error.message}</p>;

    // Destructuring homeData if it exists, otherwise use default values
    const {
        Banner: bannerData = {},
        Partners: partnersData = {},
        Services: servicesData = {},
        Solutions: solutionsData = {},
        Industries: industriesData = {},
        Vendors: vendorsData = {},
        Contactus: contactusData = {},
    } = homeData?.data?.attributes || {};

    return (
        <section>
            <SEOMetaTags apiId={apiId} filters=""/>

            <div className="container">
                <div data-testid="home-banner-section" className="container-fluid section-spacing" height='450' width='1361'>
                    <Banner apiId={apiId} {...bannerData} />
                </div>

                <div data-testid="home-brand-recognition-section" className="container-fluid section-spacing">
                    <Partners apiId={apiId} {...partnersData} />
                </div>

                <div data-testid="home-services-section" className="container-fluid section-spacing partner-cls-set">
                    <div className="row content-middle">
                        <Services apiId={apiId} {...servicesData} />
                    </div>
                </div>
            </div>

            <div data-testid="home-solutions-section" className="home-solutions-section section-spacing solutions-width">
                <div className="container">
                    <div className="container-fluid">
                        <Solutions apiId={apiId} {...solutionsData} />
                    </div>
                </div>
            </div>

            <div data-testid="home-vendors-section" className="home-vendors-section section-spacing">
                <div className="container">
                    <Vendors apiId={apiId} {...vendorsData} />
                </div>
            </div>

            <div className="container">
                <div data-testid="home-industries-section" className="container-fluid section-spacing">
                    <Industries apiId={apiId} {...industriesData} />
                </div>

                <div data-testid="home-contact-us-section" id="home-contact-us-section" className="container-fluid section-spacing">
                    <Contactus apiId={"/contact-us"} {...contactusData} />
                </div>
            </div>
        </section>
    );
}

export default Home;
