import {useParams} from 'react-router-dom';
import "./Solutions.css"
import "./../home/Home.css"
import Information from "./components/Information";
import PropTypes from "prop-types";
import Benefits from "./components/Benefits";
import Features from "./components/Features";
import Pricing from "./components/Pricing";
import Automation from "./components/Automation";
import useFetch from "../../hooks/useFetch";
import React, {useEffect, useState} from "react";
import ErrorHandling from "../../components/ErrorHandling";
import LoadingComponent from "../../components/LoadingComponent";
import SEOMetaTags from "../../components/SEOMetaTags";

function Solutions({apiId}) {
    const {slug} = useParams();

    const url = process.env.REACT_APP_API_PATH + apiId + "?filters[category][slug][$eq]=" + slug;
    const {response, loading, error} = useFetch(url);

    const [active, setActive] = useState(true);

    useEffect(() => {
        if (response && response.data !== undefined) {
            const data = response.data || [];

            const isActiveArray = data.map(solution => solution.attributes.is_active);
            const anyActive = isActiveArray.includes(true);

            setActive(anyActive);
        }
    }, [response]);

    if (loading) return <LoadingComponent/>;

    return (
        active ? (<section className="section-spacing">
            <SEOMetaTags apiId={apiId} filters={"&filters[category][slug][$eq]=" + slug}/>

            <div className="product-information-container section-spacing section-spacing-bottom">
                <div className="container">
                    <div className="container-fluid">
                        <Information apiId={apiId} slug={slug}/>
                    </div>
                </div>
            </div>

            <div className="product-benefits-container section-spacing section-spacing-bottom">
                <div className="container">
                    <div className="container-fluid">
                        <Benefits apiId={apiId} slug={slug}/>
                    </div>
                </div>
            </div>

            <Features apiId={apiId} slug={slug} isRelatedServices={false}/>

            <div className="product-pricing-container section-spacing">
                <div className="container">
                    <Pricing apiId={apiId} slug={slug}/>
                </div>
            </div>

            <Automation apiId={apiId} slug={slug}/>

        </section>) : (<ErrorHandling error={error ? error.message : null}/>)
    )
}

Solutions.propTypes = {
    apiId: PropTypes.string.isRequired
};

export default Solutions;