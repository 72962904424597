import PropTypes from "prop-types";

function PolicyAccordion({policyInformation}) {
    return (
        <div className="legal-content-container">
            <div className="accordion" id="policyAccordion">
                {
                    policyInformation.map((item) => (
                        <div className="accordion-item accordion-main-container" key={item.id}>
                            <div className="accordion-header" id={"heading-" + item.id}>
                                <button className="accordion-button collapsed accordion-button-container"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target={"#collapse-" + item.id}
                                        aria-expanded="false"
                                        aria-controls={"collapse-" + item.id}>
                                    <h4>
                                        {item.heading}
                                    </h4>
                                </button>
                            </div>

                            <div id={"collapse-" + item.id} className="accordion-collapse collapse"
                                 aria-labelledby={"heading-" + item.id} data-bs-parent="#policyAccordion">
                                <div className="accordion-body accordion-body-container"
                                     dangerouslySetInnerHTML={{__html: item.content}}></div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

PolicyAccordion.propTypes = {
    policyInformation: PropTypes.array.isRequired
};

export default PolicyAccordion;