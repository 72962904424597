import "./Header.css";
import useFetch from "../../hooks/useFetch";
import NavigationComponent from "./NavigationComponent";

const Header = () => {
    const url = process.env.REACT_APP_API_PATH + "/main-menu?populate[0]=MainMenuItems&populate[1]=MainMenuItems.menu_dropdowns&populate[2]=MainMenuItems.menu_dropdowns.MenuLinks&populate[3]=MainMenuItems.group_categories";
    const {response} = useFetch(url);

    const menuItems = [];
    const callToActionButtons = [];
    createResponse(response);

    return (
        <header className="header-main-container">
            <section className="container">
                <nav className="navbar navbar-expand-lg border-bottom-1 content-middle" data-testid="header-navigation">
                    <div className="container-fluid">
                        <a data-testid="header-logo-link" className="navbar-brand" href="/">
                            <img src={process.env.PUBLIC_URL + '/images/misu-logo.png'} alt="logo"
                                 className="nav-logo"/>
                        </a>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        {
                            menuItems.length > 0 ?
                                <NavigationComponent menuItems={menuItems}
                                                     callToActionButtons={callToActionButtons}/> : ""
                        }
                    </div>
                </nav>
            </section>
        </header>
    )

    /*
    * Building fetch response to loop through navigation data
    */
    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data.attributes;
            const navigationItems = data.MainMenuItems;

            navigationItems.forEach(function (item) {
                const categories = item.menu_dropdowns ? item.menu_dropdowns.data : [];
                const group_categories = item.group_categories ? item.group_categories.data : [];
                const categoryItems = [];
                const groupCategoryItems = [];

                categories.forEach(function (menuItem) {
                    const category = menuItem.attributes;
                    categoryItems.push({
                        id: menuItem.id,
                        name: category.name,
                        slug: category.slug,
                        is_active: category.is_active,
                    });
                })

                if (group_categories) {
                    group_categories.forEach(function (menuItem) {
                        const category = menuItem.attributes;
                        groupCategoryItems.push({
                            id: menuItem.id,
                            name: category.name,
                            slug: category.slug,
                            is_active: category.is_active,
                        });
                    })
                }

                if (item.is_button) {
                    callToActionButtons.push({
                        id: item.id,
                        title: item.title,
                        slug: item.slug,
                        type: item.type,
                        is_active: item.is_active,
                    });
                } else {
                    menuItems.push({
                        id: item.id,
                        title: item.title,
                        slug: item.slug,
                        is_active: item.is_active,
                        categories: categoryItems,
                        group_categories: groupCategoryItems
                    });
                }
            })
        }
    }
}

export default Header;