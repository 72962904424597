import React, { useState, useEffect } from "react";
import "../BlogDetail.css";

const CommentsSection = ({ blogId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorAvatar, setAuthorAvatar] = useState("");
  const [threadOf, setThreadOf] = useState(null);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_PATH}/comments/api::blog-content.blog-content:${blogId}`
    )
      .then((response) => response.json())
      .then((data) => {
        const approvedComments = data.filter(
          (comment) => !comment.blocked && comment.approvalStatus === "APPROVED"
        );
        setComments(approvedComments);
      })
      .catch((error) => {
        console.error("There was an error fetching the comments!", error);
      });
  }, [blogId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const commentPayload = {
      author: {
        id: hashName(authorName),
        name: authorName,
        email: authorEmail,
        avatar: authorAvatar || "https://via.placeholder.com/150",
      },
      content: newComment,
      threadOf: threadOf,
    };

    fetch(
      `${process.env.REACT_APP_API_PATH}/comments/api::blog-content.blog-content:${blogId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(commentPayload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.blocked && data.approvalStatus === "APPROVED") {
          setComments([...comments, data]);
        }
        setNewComment("");
        setAuthorName("");
        setAuthorEmail("");
        setAuthorAvatar("");
        setThreadOf(null);
      })
      .catch((error) => {
        console.error("There was an error posting the comment!", error);
      });
  };

  const hashName = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      const char = name.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
    return hash.toString();
  };

  return (
    <div>
      <div className="comment-form-container">
        <h3>Add a Comment</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
            placeholder="Name"
            required
          />
          <input
            type="email"
            value={authorEmail}
            onChange={(e) => setAuthorEmail(e.target.value)}
            placeholder="Email (optional)"
          />
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Comment"
            required maxLength="250"
          />
          <button type="submit">Submit</button>
        </form>
      </div>

      <div className="comment-list">
        <h4>Comments</h4>
        {comments.map((comment) => (
          <div key={comment.id} className="comment-item">
            <div className="comment-author">
              <strong>{comment.author.name}</strong>
              {comment.author.email && <small>({comment.author.email})</small>}
            </div>
            <div className="comment-content">
              <p>{comment.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentsSection;
