import React, {useEffect, useState} from "react";
import "../../components/blog/Blog.css";
import useFetch from "../../hooks/useFetch";
import "../company/Company.css";
import About from "./components/About";
import TeamExperience from "./components/TeamExperience";
import Contactus from "../contactus/Contactus.js";
import ErrorHandling from "../../components/ErrorHandling";
import LoadingComponent from "../../components/LoadingComponent";
import TeamMembers from "./components/TeamMembers";
import CompanyTimeLine from "./components/CompanyTimeLine.js";
import Support from "./components/Support";
import SEOMetaTags from "../../components/SEOMetaTags";

function Company() {
    const apiId = "/company";

    const url = `${process.env.REACT_APP_API_PATH}${apiId}?populate[0]=CompanyInfo`;
    const {response, error, isLoading} = useFetch(url);
    const [companyInfo, setCompanyInfo] = useState(null);

    useEffect(() => {
        if (response?.data) {
            const company = response.data.attributes.CompanyInfo;
            if (company) {
                const companyData = {
                    heading: company.Heading,
                    description: company.Description,
                };
                setCompanyInfo(companyData);
            }
        }
    }, [response]);

    if (isLoading) return <LoadingComponent/>;
    if (error) return <ErrorHandling error={error}/>;
    if (!companyInfo) return <p></p>;

    return (
        <section>
          <SEOMetaTags apiId={apiId} filters=""/>

          <div className="company-main-container">
            <div className="container">
              <div className=" section-spacing container-fluid">
                <h1 className="Heading">{companyInfo.heading}</h1>
                <div className="Description">{companyInfo.description}</div>
              </div>

              <CompanyTimeLine apiId={apiId}/>

              <div className="company-about-us-container section-spacing">
                <div className="container-fluid">
                  <About apiId={apiId}/>
                </div>
              </div>
            </div>

            <div className="company-experience-container section-spacing section-spacing-bottom">
              <div className="container">
                <div className="container-fluid">
                  <TeamExperience apiId={apiId}/>
                </div>
              </div>
            </div>

            <div className="company-team-container section-spacing">
              <div className="container">
                <div className="container-fluid">
                  <TeamMembers apiId={apiId}/>
                </div>
              </div>
            </div>
            <div className="section-spacing">
              <div className="container">
                <div className="container-fluid">
                  <Support apiId={apiId}/>
                </div>
              </div>
            </div>
            <div className="container">
              <div className=" id=home-contact-us-section  section-spacing">
                <Contactus apiId={"/contact-us"}/>
              </div>
            </div>
          </div>
        </section>
    );
}

export default Company;
