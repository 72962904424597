import React, {useEffect, useMemo, useState} from "react";
import "../../components/blog/Blog.css";
import useFetch from "../../hooks/useFetch";
import {useParams} from "react-router-dom";
import "./BlogDetail.css";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorHandling from "../../components/ErrorHandling";
import {getImageUrl} from "../../services/util";
import RelatedBlogComponent from "../../components/blog/relatedBlog";
import CommentsSection from "../../pages/blog/components/CommentSection";
import SEOMetaTags from "../../components/SEOMetaTags";

function BlogDetailComponent() {
    const apiId = "/blog-contents/";
    const {id} = useParams();

    const url =
        process.env.REACT_APP_API_PATH +
        apiId +
        id +
        "?populate[0]=image&populate[1]=button&populate[2]=related_blogs.image&populate[3]=related_blogs.button&filters[$and][0][is_active][$eq]=true&populate[4]=Comments";
    const {response, loading, error} = useFetch(url);

    const [blogContent, setBlogContent] = useState({});

    const memoizedBlogContent = useMemo(() => {
        return createResponse(response);
    }, [response]);

    useEffect(() => {
        if (memoizedBlogContent) {
            setBlogContent(memoizedBlogContent);
        }
    }, [memoizedBlogContent]);

    if (loading) return <LoadingComponent/>;

    function formatDate(dateString) {
        const options = {year: "numeric", month: "long", day: "numeric"};
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB", options);
    }

    return error ? (
        <ErrorHandling error={error}/>
    ) : (
        <section>
          <SEOMetaTags apiId={"/blog-contents/" + id} filters=""/>

            <div className="container section-spacing">
                <div className="container-fluid">
                    {blogContent ? (
                        <div className="blog-detail-main-container text-center">
                            {blogContent.categories ? (
                                <div className="blog-category-container text-center">
                                    <div className="badge blog-badge">{blogContent.categories}</div>
                                </div>
                            ) : null}

                            <h1 className="banner-heading-container">{blogContent.heading}</h1>

                            <div className="Blog-Detail-Date-Time">
                                {formatDate(blogContent.date)}
                            </div>

                            {blogContent.image ? (
                                <img
                                    className="img-fluid Blog-Detail-Image"
                                    alt={blogContent.alt}
                                    src={getImageUrl(blogContent.image)}
                                />
                            ) : null}

                            <div
                                className="blog-detail-content"
                                dangerouslySetInnerHTML={{__html: blogContent.content}}
                            ></div>

                            {blogContent.tags ? (
                                <div className="badge blog-badge">Tags: {blogContent.tags}</div>
                            ) : null}

                            {/* Comments Section */}
                            <div className="section-spacing">
                                <CommentsSection blogId={blogContent.id}/>
                            </div>

                            {blogContent.related_blogs &&
                            blogContent.related_blogs.length > 0 ? (
                                <div className="related-blogs-container text-start section-spacing">
                                    <h2>Related articles</h2>
                                    <RelatedBlogComponent blogs={blogContent.related_blogs}/>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div className="empty-message text-center">
                            No blogs have been created yet.
                        </div>
                    )}
                </div>
            </div>
        </section>
    );

    function createResponse(response) {
        if (response && response.data !== undefined) {
            if (response.data) {
                const blog = response.data.attributes ? response.data.attributes : [];
                const image = blog.image ? blog.image.data.attributes : [];
                const button = blog.button ? blog.button : [];

                return {
                    id: response.data.id,
                    image: image.url,
                    alt: image.alternativeText,
                    heading: blog.heading,
                    summary: blog.summary,
                    is_primary: blog.is_primary,
                    date: blog.createdAt,
                    button: button.title,
                    open_view_window: button.open_new_window,
                    categories: blog.categories,
                    content: blog.content,
                    tags: blog.tags,
                    related_blogs: blog.related_blogs ? blog.related_blogs.data : [],
                };
            }
        }
    }
}

export default BlogDetailComponent;
