import "react-icons";
import useFetch from "../../hooks/useFetch";
import React, {useEffect, useMemo, useState} from "react";
import LoadingComponent from "../LoadingComponent";
import {useNavigate} from "react-router-dom";
import {getKeycloakInstance} from "../../Keycloak";
import {RiShutDownLine} from "react-icons/ri";
import {updateCartCount} from "../../services/util";


const Sidebar = () => {
    const url = process.env.REACT_APP_API_PATH + "/logged-in-menu?populate[0]=AdminMenuLink.icon";
    const {response, loading} = useFetch(url);

    const [menuItems, setMenuItems] = useState([]);

    const memoizedMenuItems = useMemo(() => {
        return createResponse(response);
    }, [response]);

    const navigate = useNavigate();

    useEffect(() => {
        if (memoizedMenuItems) {
            setMenuItems(memoizedMenuItems.menuItem);
        }
    }, [memoizedMenuItems]);

    useEffect(() => {
        updateCartCount();
    }, [menuItems]);

    const handleLogout = async () => {
        try {
            await getKeycloakInstance().logout({redirectUri: window.location.origin + '/'});
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    if (loading) return <LoadingComponent/>;

    return (
        <div className="col-xl-2 col-lg-3 col-md-4 col-auto client-portal-sidebar-container position-relative">
            <div
                className="d-flex flex-column align-items-center align-items-sm-start pt-3">
                <a data-testid="header-logo-link"
                   className="d-flex align-items-center pb-4 mb-md-0 me-md-auto text-decoration-none logo-sidebar"
                   href="/dashboard">
                    <img src={process.env.PUBLIC_URL + '/images/misu-logo.png'} alt="logo"
                         className="nav-logo img-fluid d-none d-sm-inline"/>
                </a>

                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start portal-sidebar-container">
                    {
                        menuItems ? menuItems.map((menuItem) => (
                            Object.keys(menuItem).length !== 0 ?
                                <li className="nav-item portal-nav-item py-3 content-middle" key={menuItem.id}>
                                    {
                                        menuItem.external_link !== null ? (
                                            <a href={menuItem.external_link} target="_blank" rel="noreferrer" className="text-decoration-none align-middle px-0">
                                                <img src={menuItem.icon} alt={menuItem.alt}
                                                     className="portal-sidebar-icons"/>

                                                <span
                                                    className="ms-1 d-none d-sm-inline portal-sidebar-items">{menuItem.heading}
                                                    <span className={(menuItem.slug).replace(/\//g, "") + " ms-2"}
                                                          id={(menuItem.slug).replace(/\//g, "")}></span>
                                        </span>
                                            </a>
                                        ) : (<a href={menuItem.slug} className="text-decoration-none align-middle px-0">
                                            <img src={menuItem.icon} alt={menuItem.alt}
                                                 className="portal-sidebar-icons"/>

                                            <span
                                                className="ms-1 d-none d-sm-inline portal-sidebar-items">{menuItem.heading}
                                                <span className={(menuItem.slug).replace(/\//g, "") + " ms-2"}
                                                      id={(menuItem.slug).replace(/\//g, "")}></span>
                                        </span>
                                        </a>)
                                    }
                                </li> : null
                        )) : null
                    }
                </ul>

                <div className="sidebar-logout-container">
                    <button className="btn btn-secondary btn-lg btn-block d-none d-sm-inline" onClick={handleLogout}>
                        Logout
                    </button>

                    <RiShutDownLine className="sidebar-logout-icon d-block d-lg-none d-md-none" onClick={handleLogout}/>
                </div>
            </div>
        </div>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];

            if (data && data.AdminMenuLink) {
                const menuItem = data.AdminMenuLink.map((menu) => {
                    const activeItem = {};
                    const icon = menu.icon.data ? menu.icon.data.attributes : [];

                    if (menu.is_active) {
                        return {
                            id: menu.id,
                            heading: menu.heading,
                            slug: menu.slug,
                            icon: icon.url,
                            external_link: menu.external_link ?? null,
                            alt: icon.alternativeText,
                        }
                    }

                    return activeItem;
                })
                return {menuItem}
            }
        }
    }
}

export default Sidebar;