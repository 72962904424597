import React, {useCallback, useEffect, useState} from "react";
import {
    getAccountInformation,
    requestAccountRemoval,
    requestEmailAddressUpdate,
    resetPasswordRequest, updateAccountInformation
} from "../../hooks/ClientPortal";
import LoadingComponent from "../../components/LoadingComponent";
import {verifyIfKeycloakInit} from "../../Keycloak";

function AccountInformation() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        primary_phone: '',
        billing_address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: '',
            postal_code: ''
        }
    });

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    const token = verifyIfKeycloakInit();

    const getAccountInformationData = useCallback(async () => {
        if (token) {
            setLoading(true);

            try {
                const accountInformation = await getAccountInformation(token);

                if (accountInformation && accountInformation.status === 'success') {
                    setFormData(accountInformation.data);
                } else {
                    setErrors(accountInformation ? accountInformation.data : 'Unknown error');
                }
            } catch (error) {
                setErrors(error.message || 'An unknown error occurred');
                console.error('Error fetching form data:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [token]);

    useEffect(() => {
        getAccountInformationData();
    }, [getAccountInformationData]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (name.startsWith('billing_address')) {
            const subField = name.split('.')[1];

            setFormData(prevFormData => ({
                ...prevFormData,
                billing_address: {
                    ...prevFormData.billing_address,
                    [subField]: value
                }
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    async function handleEmailResetRequest() {
        if (token) {
            setLoading(true);
            try {
                const response = await resetPasswordRequest(token);
                if (response) {
                    if (response.success) {
                        setMessage(response.message);
                        setShowAlert(true)
                    } else {
                        setMessage(response.error);
                        setShowAlert(true);
                    }
                }
                setLoading(false);
            } catch (error) {
                setError("An error occurred during the email update request");
                setShowAlert(true);
                setLoading(false);
            }
        }
    }

    async function handleAccountRemoveRequest() {
        if (token) {
            setLoading(true);
            try {
                const response = await requestAccountRemoval(token);
                if (response) {
                    if (response.success) {
                        setMessage(response.message);
                        setShowAlert(true)
                    } else {
                        setMessage(response.error);
                        setShowAlert(true);
                    }
                }
                setLoading(false);
            } catch (error) {
                setError("An error occurred during the account remove request");
                setShowAlert(true);
                setLoading(false);
            }
        }
    }

    async function handleEmailUpdateRequest() {
        if (token) {
            setLoading(true);
            try {
                const response = await requestEmailAddressUpdate(token, formData.first_name + " " + formData.last_name);
                if (response) {
                    if (response.success) {
                        setMessage(response.message);
                        setShowAlert(true)
                    } else {
                        setMessage(response.error);
                        setShowAlert(true);
                    }
                }
                setLoading(false);
            } catch (error) {
                setError("An error occurred during the email update request");
                setShowAlert(true);
                setLoading(false);
            }
        }
    }

    function handleAlertClose() {
        setMessage(null);
        setError(null);
        setValidationErrors({})
        setShowAlert(false);
    }

    const validate = () => {
        const validation = {};

        if (!formData.first_name) validation.first_name = 'First Name is required';
        if (!formData.last_name) validation.last_name = 'Last Name is required';
        if (!formData.primary_phone) validation.primary_phone = 'Phone Number is required';

        if (!formData.billing_address) {
            formData.billing_address = {};
        }

        if (!formData.billing_address.line1) validation.billing_address = {line1: 'Line 1 is required'};
        if (!formData.billing_address.city) validation.billing_address = {
            ...validation.billing_address,
            city: 'City is required'
        };
        if (!formData.billing_address.state) validation.billing_address = {
            ...validation.billing_address,
            state: 'State is required'
        };
        if (!formData.billing_address.postal_code) validation.billing_address = {
            ...validation.billing_address,
            postal_code: 'Postal Code is required'
        };
        if (!formData.billing_address.country) validation.billing_address = {
            ...validation.billing_address,
            country: 'Country is required'
        };

        return validation;
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setValidationErrors(validationErrors);
            setShowAlert(true);
        }

        if (token && Object.keys(validationErrors).length === 0) {
            setLoading(true);
            try {
                const response = await updateAccountInformation(token, formData);
                if (response) {
                    if (response.status === 'success') {
                        setMessage(response.data);
                        setShowAlert(true)
                    } else {
                        setMessage("An error occurred while updating account information");
                        setShowAlert(true);
                    }
                }
                setLoading(false);
            } catch (error) {
                setError("An error occurred while updating account information");
                setShowAlert(true);
                setLoading(false);
            }
        }
    }

    return (
        <section className="dashboard-main-container">
            {
                loading ? <LoadingComponent/> : ""
            }

            <div className="container-fluid section-spacing">
                <form onSubmit={handleSubmit}>
                    <div className="card-information-container">
                        <div className="row section-spacing-bottom information-row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <h1>Account Information</h1>
                                <h6 className="mb-3">Manage you Account Information</h6>
                            </div>

                            <div
                                className="col-lg-6 col-md-6 col-sm-12 col-12 d-inline-flex align-items-center justify-content-end">
                                <div className="action-container">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block me-2">
                                        Save
                                    </button>

                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a className="dropdown-toggle btn btn-secondary btn-lg btn-block mb-0" role="button"
                                       data-bs-toggle="dropdown" aria-expanded="false">
                                        Actions
                                    </a>

                                    <ul className="dropdown-menu border-radius-0 mt-0">
                                        <li>
                                            <button type="button" className="dropdown-item w-100 cursor-pointer py-2"
                                                    onClick={() => handleEmailResetRequest()}>
                                                Reset Password
                                            </button>
                                        </li>

                                        <li>
                                            <button type="button" className="dropdown-item w-100 cursor-pointer py-2"
                                                    onClick={() => handleEmailUpdateRequest()}>
                                                Request Email Update
                                            </button>
                                        </li>

                                        <li>
                                            <button type="button" className="dropdown-item w-100 cursor-pointer py-2"
                                                    onClick={() => handleAccountRemoveRequest()}>
                                                Request account removal
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">
                                {showAlert && message && (
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        {message}
                                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                    </div>
                                )}

                                {showAlert && error && (
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        {error}
                                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                    </div>
                                )}
                            </div>
                        </div>

                        {
                            Object.keys(errors).length !== 0 ? <div className="error-description-container text-center">
                                {errors || "Sorry, we couldn't find what you were looking for. Please double-check the information and try again!"}
                            </div> : <section>
                                {showAlert && Object.keys(validationErrors).length !== 0 && (
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        Please fill out all required fields before submitting
                                        <button type="button" className="btn-close"
                                                onClick={handleAlertClose}></button>
                                    </div>
                                )}

                                <div className="row mb-3">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="form-input-container">
                                            <div className="form-heading-container mb-2">
                                                First Name *
                                            </div>
                                            <input type="text"
                                                   className={`form-control checkout-inputs ${validationErrors.first_name ? 'input-required' : ''}`}
                                                   onChange={handleChange} name="first_name"
                                                   value={formData.first_name || ''}
                                                   placeholder="First Name"/>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="form-input-container">
                                            <div className="form-heading-container mb-2">
                                                Last Name *
                                            </div>
                                            <input type="text"
                                                   className={`form-control checkout-inputs ${validationErrors.last_name ? 'input-required' : ''}`}
                                                   onChange={handleChange} name="last_name"
                                                   value={formData.last_name || ''}
                                                   placeholder="Last Name"/>
                                        </div>
                                    </div>

                                    <div className="col"></div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="form-input-container">
                                            <div className="form-heading-container mb-2">
                                                Company Name
                                            </div>
                                            <input type="text" className="form-control checkout-inputs"
                                                   onChange={handleChange} name="company_name"
                                                   value={formData.company_name || ''}
                                                   placeholder="Company Name"/>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="form-input-container">
                                            <div className="form-heading-container mb-2">
                                                Phone Number *
                                            </div>
                                            <input type="text"
                                                   className={`form-control checkout-inputs ${validationErrors.primary_phone ? 'input-required' : ''}`}
                                                   onChange={handleChange} name="primary_phone"
                                                   value={formData.primary_phone || ''}
                                                   placeholder="Phone Number"/>
                                        </div>
                                    </div>

                                    <div className="col"></div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="form-input-container">
                                            <div className="form-heading-container mb-2">
                                                Billing Information
                                            </div>
                                            <input type="text"
                                                   className={`form-control checkout-inputs mb-1 ${validationErrors.billing_address?.line1 ? 'input-required' : ''}`}
                                                   placeholder="Line 1 *"
                                                   onChange={handleChange} name="billing_address.line1"
                                                   value={formData.billing_address['line1'] || ''}/>

                                            <input type="text" className="form-control checkout-inputs mb-1"
                                                   placeholder="Line 2"
                                                   onChange={handleChange} name="billing_address.line2"
                                                   value={formData.billing_address['line2'] || ''}/>

                                            <input type="text"
                                                   className={`form-control checkout-inputs mb-1 ${validationErrors.billing_address?.city ? 'input-required' : ''}`}
                                                   placeholder="City *"
                                                   onChange={handleChange} name="billing_address.city"
                                                   value={formData.billing_address['city'] || ''}/>

                                            <input type="text"
                                                   className={`form-control checkout-inputs mb-1 ${validationErrors.billing_address?.state ? 'input-required' : ''}`}
                                                   placeholder="State *"
                                                   onChange={handleChange} name="billing_address.state"
                                                   value={formData.billing_address['state'] || ''}/>

                                            <input type="text"
                                                   className={`form-control checkout-inputs mb-1 ${validationErrors.billing_address?.country ? 'input-required' : ''}`}
                                                   placeholder="Country *"
                                                   onChange={handleChange} name="billing_address.country"
                                                   value={formData.billing_address['country'] || ''}/>

                                            <input type="text"
                                                   className={`form-control checkout-inputs mb-1 ${validationErrors.billing_address?.postal_code ? 'input-required' : ''}`}
                                                   onChange={handleChange} name="billing_address.postal_code"
                                                   value={formData.billing_address['postal_code'] || ''}
                                                   placeholder="Postal Code *"/>
                                        </div>
                                    </div>

                                    <div className="col"></div>
                                </div>

                                <div className="row">
                                    <div className="col-12 d-inline-flex align-items-center justify-content-end">
                                        <div className="action-container">

                                        </div>
                                    </div>
                                </div>
                            </section>
                        }
                    </div>
                </form>
            </div>
        </section>
    )
}

export default AccountInformation;