import React, { memo, useEffect, useState } from 'react';
import { getImageUrl } from "../../../services/util";

const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

const Partners = memo(({ apiId }) => {
  const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=PartnersComponent&populate[1]=PartnersComponent.PartnerImagesRowOne&populate[2]=PartnersComponent.PartnerImagesRowOne.image&populate[3]=PartnersComponent.PartnerImagesRowTwo&populate[4]=PartnersComponent.PartnerImagesRowTwo.image&populate[5]=PartnersComponent.PartnerImagesRowThree&populate[6]=PartnersComponent.PartnerImagesRowThree.image`;

  const [partners, setPartners] = useState([]);
  const [partnerImagesRowOne, setPartnerImagesRowOne] = useState([]);
  const [partnerImagesRowTwo, setPartnerImagesRowTwo] = useState([]);
  const [partnerImagesRowThree, setPartnerImagesRowThree] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem('partnersData');
        const cacheTime = localStorage.getItem('partnersDataTime');
        const now = Date.now();

        if (cachedData && cacheTime && now - cacheTime < CACHE_DURATION) {
          const parsedData = JSON.parse(cachedData);
          setPartners(parsedData.partners);
          setPartnerImagesRowOne(parsedData.partnerImagesRowOne);
          setPartnerImagesRowTwo(parsedData.partnerImagesRowTwo);
          setPartnerImagesRowThree(parsedData.partnerImagesRowThree);
        } else {
          const response = await fetch(url);
          const data = await response.json();

          const newData = createResponse(data);
          setPartners(newData.partners);
          setPartnerImagesRowOne(newData.partnerImagesRowOne);
          setPartnerImagesRowTwo(newData.partnerImagesRowTwo);
          setPartnerImagesRowThree(newData.partnerImagesRowThree);

          localStorage.setItem('partnersData', JSON.stringify(newData));
          localStorage.setItem('partnersDataTime', now.toString());
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div className="row content-middle partner-cls-set">
      <div className="col-lg-7 col-md-7 col-sm-12 col-12 mb-3">
        <div className="position-relative partners-images-main-container">
          {partnerImagesRowOne.map((item) => (
            <div className={`partners-images-section-top-${item.position}`} key={item.id}>
              {renderPartnerImage(item)}
            </div>
          ))}
          {partnerImagesRowTwo.map((item) => (
            <div className={`partners-images-section-mid-${item.position}`} key={item.id}>
              {renderPartnerImage(item)}
            </div>
          ))}
          {partnerImagesRowThree.map((item) => (
            <div className={`partners-images-section-bottom-${item.position}`} key={item.id}>
              {renderPartnerImage(item)}
            </div>
          ))}
        </div>
      </div>

      <div className="col-lg-5 col-md-5 col-sm-12 col-12 mb-3">
        {partners.map((item) => (
          <div className="partners-main-container" key={item.id}>
            <div className="partners-heading-container">
              <h2>{item.heading}</h2>
            </div>
            <div className="partners-description-container">{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
});

function createResponse(response) {
  const partners = [];
  const partnerImagesRowOne = [];
  const partnerImagesRowTwo = [];
  const partnerImagesRowThree = [];

  if (response && response.data !== undefined) {
    const data = response.data.attributes || {};
    if (data.PartnersComponent) {
      partners.push({
        id: data.PartnersComponent.id,
        heading: data.PartnersComponent.heading,
        description: data.PartnersComponent.description,
      });

      if (data.PartnersComponent.PartnerImagesRowOne) {
        data.PartnersComponent.PartnerImagesRowOne.forEach((image) => {
          const partnerRowOne = image.image ? image.image.data.attributes : {};
          partnerImagesRowOne.push({
            id: image.id,
            url: image.url ?? null,
            position: image.position,
            logo: partnerRowOne.url,
            alt: partnerRowOne.alt,
          });
        });
      }

      if (data.PartnersComponent.PartnerImagesRowTwo) {
        data.PartnersComponent.PartnerImagesRowTwo.forEach((image) => {
          const partnerRowTwo = image.image ? image.image.data.attributes : {};
          partnerImagesRowTwo.push({
            id: image.id,
            url: image.url ?? null,
            position: image.position,
            logo: partnerRowTwo.url,
            alt: partnerRowTwo.alt,
          });
        });
      }

      if (data.PartnersComponent.PartnerImagesRowThree) {
        data.PartnersComponent.PartnerImagesRowThree.forEach((image) => {
          const partnerRowThree = image.image ? image.image.data.attributes : {};
          partnerImagesRowThree.push({
            id: image.id,
            url: image.url ?? null,
            position: image.position,
            logo: partnerRowThree.url,
            alt: partnerRowThree.alt,
          });
        });
      }
    }
  }

  return {
    partners,
    partnerImagesRowOne,
    partnerImagesRowTwo,
    partnerImagesRowThree,
  };
}

function renderPartnerImage(item) {
  return item.url ? (
    <a href={item.url} target="_blank" rel="noreferrer">
      <img loading="lazy" className="img-fluid" src={getImageUrl(item.logo)} alt={item.alt} />
    </a>
  ) : (
    <img loading="lazy" className="img-fluid" src={getImageUrl(item.logo)} alt={item.alt} />
  );
}

export default Partners;
