import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./Checkout.css"
import {loadStripe} from '@stripe/stripe-js';
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import ErrorHandling from "../../components/ErrorHandling";
import {verifyIfKeycloakInit} from "../../Keycloak";
import {useLocation, useNavigate} from "react-router-dom";
import SEOMetaTags from "../../components/SEOMetaTags";

const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_PUBLIC_KEY);

function Payment() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const redirectResponse = location.state || {};

        if (!redirectResponse.response || !redirectResponse.response.redirect_from_cart) {
            // Redirect back to the checkout page if condition is not met
            const token = verifyIfKeycloakInit();

            let redirectUrl = token ? "/auth/shopping-cart" : "/shopping-cart";
            navigate(redirectUrl);
        }
    }, [location.state, navigate]);

    const response = useMemo(() => {
        const storedData = localStorage.getItem("orderlines");
        return storedData ? JSON.parse(storedData) : [];
    }, []);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (response.length === 0) {
            setError("An error occurred during the process.")
        }
    }, [response]);

    const fetchClientSecret = useCallback(() => {
        if (response) {
            const token = verifyIfKeycloakInit();
            const authorization = token ? `Bearer ${token}` : null;

            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.api+json',
            };

            if (authorization) {
                headers['Authorization'] = authorization;
            }

            const requestData = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(response)
            };

            return fetch(process.env.REACT_APP_API_INTEGRATION_PATH + '/V1/stripe/init/checkout', requestData)
                .then((res) => {
                    return res.json();
                })
                .then((json) => {
                    return json.data.clientSecret;
                });
        }

    }, [response]);

    const options = {fetchClientSecret};

    return (
        error ? (<div className="text-center">
                <ErrorHandling error={error}/>

                <div className="row mb-4">
                    <div className="col-12">
                        <a href="/shopping-cart"
                           className="btn btn-primary btn-lg btn-block me-2 content-middle">
                            Continue Shopping
                        </a>
                    </div>
                </div>
            </div>) :
            (<div id="checkout">
                <SEOMetaTags apiId={"/shopping-cart"} filters=""/>

                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <EmbeddedCheckout/>
                </EmbeddedCheckoutProvider>
            </div>)
    )
}

export default Payment;