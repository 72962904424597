import {getKeycloakInstance} from "../../Keycloak";
import React, {useCallback, useEffect, useState} from "react";
import {getStripeCustomerPortal} from "../../hooks/ClientPortal";
import LoadingComponent from "../../components/LoadingComponent";
import PropTypes from "prop-types";

function StripeCustomerPortal({heading}) {
    const keycloak = getKeycloakInstance();

    const [customerPortal, setCustomerPortal] = useState({});
    const [loading, setLoading] = useState(true);

    const getStripeCustomerPortalData = useCallback(async () => {
        if (keycloak.authenticated) {
            try {
                const customerPortalURL = await getStripeCustomerPortal(keycloak.token);
                if (customerPortalURL) {
                    setCustomerPortal(customerPortalURL);
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
            }
        }
    }, [keycloak]);

    useEffect(() => {
        getStripeCustomerPortalData();
    }, [getStripeCustomerPortalData]);

    return (
        <div className="dashboard-main-container">
            {
                loading ? <LoadingComponent/> : ""
            }

            <div className="container-fluid section-spacing">
                <h1>{heading}</h1>

                <div className="dashboard-container section-spacing">
                    <div className="dashboard-container-row">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-12 section-spacing-top">
                                    You are about to be redirected to the Stripe Customer Portal to manage your
                                    subscriptions. This will open in a new window. Please ensure that any unsaved
                                    changes on this site are saved before proceeding.
                                </div>

                                {
                                    customerPortal ?
                                        <div className="col-12 mt-3">
                                            <a href={customerPortal.url} target="_blank" rel="noreferrer"
                                               className="btn btn-secondary btn-lg btn-block">
                                                Proceed to Stripe Customer Portal
                                            </a>
                                        </div>
                                        :
                                        <div className="btn btn-secondary btn-lg btn-block disabled">
                                            Proceed to Stripe Customer Portal
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

StripeCustomerPortal.propTypes = {
    heading: PropTypes.string.isRequired
};

export default StripeCustomerPortal;