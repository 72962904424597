import useFetch from "../../hooks/useFetch";
import { getImageUrl } from "../../services/util";

function ConsultationComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=ConsultationButton&populate[1]=CompanyLogo";
    const {response} = useFetch(url);

    const consultation = [];
    createResponse(response);

    return (
        <div className="consultation-main-container">
            {
                consultation ?
                    consultation.map((item) => (
                        <div key={item.name}>
                            <div className="footer-logo-container mb-4">
                                <a data-testid="header-logo-link" href="/">
                                    <img className="footer-logo img-fluid" alt={item.alt} loading="lazy"
                                         src={ getImageUrl(item.imagePath)}/>
                                </a>
                            </div>

                            <div className="consultation-container">
                                {
                                    item.button.map((button) => (
                                        <a href="/contact-us" key={"consultation-action-" + button.id}
                                                className={"btn-" + button.type + " btn btn-lg btn-block me-2"}>
                                            {button.title}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    )) : ""
            }
        </div>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.ConsultationButton) {
                const consultationButton = data.ConsultationButton;
                const buttonItems = [];
                consultationButton.forEach(function (button) {
                    buttonItems.push({
                        id: button.id,
                        title: button.title,
                        slug: button.slug,
                        type: button.type,
                        open_new_window: button.open_new_window,
                    })
                })

                if (data.CompanyLogo) {
                    const companyLogo = data.CompanyLogo;
                    const logo = companyLogo.data.attributes;
                    consultation.push({
                        name: logo.name,
                        alt: logo.alternativeText,
                        imagePath: logo.url,
                        button: buttonItems
                    })
                }
            }
        }
    }
}

export default ConsultationComponent;