import {AiFillLinkedin} from "react-icons/ai";
import {useEffect, useMemo} from "react";
import useFetch from "../../../hooks/useFetch";
import PropTypes from "prop-types";
import {getImageUrl} from "../../../services/util";

function TeamMembers({apiId}) {
    const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=Members.image`;
    const {response, fetchData} = useFetch(url);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const {teamMembers} = useMemo(() => {
        const teamMembers = [];

        if (response && response.data) {
            const data = response.data.attributes || {};
            if (data && data.Members) {
                data.Members.forEach(member => {
                    const image = member.image ? member.image.data.attributes : [];
                    teamMembers.push({
                        id: member.id,
                        name: member.name,
                        position: member.position,
                        description: member.description,
                        linkedin: member.linkedin,
                        image: image.url,
                        alt: image.alternativeText,
                    });
                })
            }
        }

        return {teamMembers};
    }, [response]);

    return (
        <div className="team-members-main-container">
            <div className="team-member-heading-container">
                <h2>
                    Meet the leadership team
                </h2>
            </div>

            <div className="accordion" id="accordionExample">
                <div className="row">
                    {
                        teamMembers ? teamMembers.map((member) => (
                            <div key={member.id} className="col-lg-3 col-md-6 col-sm-6 col-12 h-100 mb-4">
                                <div className="card member-card-container h-100">
                                    <div className="member-image-container">
                                        <img alt={member.alt} src={getImageUrl(member.image)} className="img-fluid"/>
                                    </div>

                                    <div className="card-body member-card-body-container">
                                        <div className="member-name-container">
                                            <h5>{member.name}</h5>
                                        </div>

                                        <div className="member-position-container position-relative">
                                            {member.position}

                                            <div className="member-socials-container">
                                                <a href={member.linkedin} target="_blank" rel="noreferrer"
                                                   className="member-details-socials">
                                                    <AiFillLinkedin/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-footer member-card-footer-container">
                                        <div className="accordion-item border-0">
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a href="#" className="collapsed member-view-more" type="button"
                                               data-bs-toggle="collapse"
                                               data-bs-target={"#collapse" + member.id}
                                               aria-expanded="false" aria-controls={"collapse" + member.id}>
                                                Read More
                                            </a>

                                            <div id={"collapse" + member.id} className="accordion-collapse collapse border-0"
                                                 aria-labelledby={"heading" + member.id}
                                                 data-bs-parent="#accordionExample">
                                                <div className="accordion-body p-0">
                                                    <div className="member-details">
                                                        <div className="pb-3"
                                                             dangerouslySetInnerHTML={{__html: member.description}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : null
                    }
                </div>
            </div>
        </div>
    )
}

TeamMembers.propTypes = {
    apiId: PropTypes.string.isRequired
};

export default TeamMembers;