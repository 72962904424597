import React, {useCallback, useEffect, useState} from "react";
import {getOrderHistory, getSalesReceiptPdf} from "../../hooks/ClientPortal";
import LoadingComponent from "../../components/LoadingComponent";
import {verifyIfKeycloakInit} from "../../Keycloak";

function Orders() {
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [filterValue, setFilterValue] = useState("");

    const token = verifyIfKeycloakInit();

    const getOrderHistoryData = useCallback(async () => {
        if (token) {
            setLoading(true);

            try {
                const orderHistory = await getOrderHistory(token);

                if (orderHistory && orderHistory.status === 'success') {
                    setResponse(orderHistory.data);
                } else {
                    setError(orderHistory ? orderHistory.data : 'Unknown error');
                }
            } catch (error) {
                setError(error.message || 'An unknown error occurred');
                console.error('Error fetching form data:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [token]);

    useEffect(() => {
        getOrderHistoryData();
    }, [getOrderHistoryData]);

    useEffect(() => {
        if (response && response.length > 0) {
            const newOrders = response.flatMap(order =>
                order.line_items.map(order_line => ({
                    date: order.created_at,
                    id: order.sales_receipt_id,
                    order_reference: order.subscription_id ? order.subscription_id : order.transaction_id,
                    product_name: order_line.item_name,
                    quantity: order_line.quantity,
                    unit_price: order_line.unit_price,
                    total: order_line.amount,
                }))
            );
            setOrders(newOrders);
            setFilteredOrders(newOrders);
        }
    }, [response]);

    useEffect(() => {
        const lowercasedFilterValue = filterValue.toLowerCase();
        const filtered = orders.filter(order => {
            const orderReference = order.order_reference || "";
            return orderReference.toLowerCase().includes(lowercasedFilterValue);
        });
        setFilteredOrders(filtered);
    }, [filterValue, orders]);

    async function handleSalesReceiptDownload(order) {
        if (!token || !order.sales_receipt_id) {
            setError("Token or sales receipt ID is missing");
            setShowAlert(true);
            return;
        }

        try {
            setLoading(true);

            const pdfUrl = await getSalesReceiptPdf(token, order.sales_receipt_id);
            if (!pdfUrl) {
                setError("PDF URL is missing");
                setShowAlert(true);
                return;
            }

            const response = await fetch(pdfUrl);
            if (!response.ok) {
                setError("Failed to fetch PDF file");
                setShowAlert(true);
                return;
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `sales-receipt-${order.sales_receipt_id}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);

            setMessage(`Successfully downloaded sales receipt for order reference: ${order.order_reference}`);
            setShowAlert(true);
        } catch (error) {
            setError(`An error occurred while downloading the sales receipt: ${error.message}`);
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    }

    function handleAlertClose() {
        setMessage(null);
        setError(null);
        setShowAlert(false);
    }

    function handleFilterChange(event) {
        setFilterValue(event.target.value);
    }

    return (
        <section className="dashboard-main-container">
            {loading ? <LoadingComponent/> : null}

            <div className="container-fluid section-spacing">
                <div className="card-information-container">
                    <div className="row section-spacing-bottom information-row">
                        <div className="col-12">
                            <h1>Order History</h1>
                            <h6 className="mb-3">View your Order History</h6>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-12">
                            {showAlert && message && (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {message}
                                    <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                </div>
                            )}

                            {showAlert && error && (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {error}
                                    <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                            <div className="order-history-filtering">
                                <input
                                    type="text"
                                    className="checkout-inputs w-100"
                                    name="order_reference"
                                    placeholder="Order Reference"
                                    value={filterValue}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <table className="table table-responsive table-responsive-sm table-responsive-md">
                                <thead className="table-primary">
                                <tr>
                                    <th>Date</th>
                                    <th>Order Reference</th>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredOrders.length > 0 ? (
                                    filteredOrders.map((order, index) => (
                                        <tr key={index}>
                                            <td>{order.date}</td>
                                            <td>{order.order_reference}</td>
                                            <td>{order.product_name}</td>
                                            <td>${order.unit_price}</td>
                                            <td>{order.quantity}</td>
                                            <td>${order.total}</td>
                                            <td>
                                                {
                                                    order.id ? (
                                                        <button type="button"
                                                                className="btn-download-link"
                                                                onClick={() => handleSalesReceiptDownload({
                                                                    sales_receipt_id: order.id,
                                                                    order_reference: order.order_reference
                                                                })}>
                                                            Download Receipt
                                                        </button>
                                                    ) : (
                                                        <div className="download-link-unavailable">Sales Receipt
                                                            Unavailable</div>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan="7">No orders found</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Orders;
