import PropTypes from "prop-types";
import useFetch from "../../../hooks/useFetch";
import React, {useEffect, useMemo, useState} from "react";
import {getImageUrl} from "../../../services/util";

function TeamExperience({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "?populate[0]=TeamExperience.Experience.icon";
    const {response} = useFetch(url);

    const [teamExperience, setTeamExperience] = useState(null);
    const [experiences, setExperiences] = useState([]);

    const memoizedTeamExperiences = useMemo(() => {
        return createResponse(response);
    }, [response]);

    useEffect(() => {
        if (memoizedTeamExperiences) {
            setTeamExperience(memoizedTeamExperiences.teamExperience);
            setExperiences(memoizedTeamExperiences.experienceContent);
        }
    }, [memoizedTeamExperiences]);

    return (
        <div className="row content-middle">
            <div className="col-12 text-center mb-5">
                {
                    teamExperience ?
                        <div className="company-team-main-container">
                            <div className="company-team-heading-container">
                                <h2>
                                    {teamExperience.heading}
                                </h2>
                            </div>
                        </div> : null
                }
            </div>

            <div className="col-12 text-center">
                <div className="row">
                {
                        experiences ?
                            experiences.map(experience => (
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-center" key={experience.id}>
                                    <div className="company-experience-icon-container">
                                        {
                                            <img key={experience.id} src={getImageUrl(experience.icon)}
                                                 alt={experience.alt}
                                                 loading="lazy" className="img-fluid"/>
                                        }
                                    </div>

                                    <div className="company-experience-heading-container">
                                        <h2>
                                            {experience.heading}
                                        </h2>
                                    </div>

                                    <div className="company-experience-description-container">
                                        {experience.description}
                                    </div>
                                </div>
                            )) : null
                    }
                </div>
            </div>
        </div>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];

            if (data) {
                const information = data.attributes ? data.attributes.TeamExperience : [];
                const experienceData = information.Experience ? information.Experience : [];

                let experienceContent = [];
                if (experienceData) {
                    experienceContent = experienceData.map((experience) => {
                        const icon = experience.icon ? experience.icon.data.attributes : [];
                        return {
                            id: experience.id,
                            icon: icon.url,
                            alt: icon.alternativeText,
                            heading: experience.heading,
                            description: experience.description,
                        }
                    })
                }

                const teamExperience = {
                    id: information.id,
                    heading: information.heading,
                    description: information.description,
                }

                return {teamExperience, experienceContent}
            }
        }
    }
}

TeamExperience.propTypes = {
    apiId: PropTypes.string.isRequired
};

export default TeamExperience;