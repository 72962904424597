import {getKeycloakInstance} from "../../Keycloak";
import React, {useCallback, useEffect, useState} from "react";
import {getDownloadAgent} from "../../hooks/ClientPortal";
import LoadingComponent from "../../components/LoadingComponent";
import {GoDownload} from "react-icons/go";

function SoftwareDownload() {
    const keycloak = getKeycloakInstance();

    const [clientData, setClientData] = useState({});
    const [loading, setLoading] = useState(true);

    const getDownloadAgentData = useCallback(async () => {
        if (keycloak.authenticated) {
            try {
                const downloadAgent = await getDownloadAgent(keycloak.token);
                if (downloadAgent) {
                    setClientData(downloadAgent);
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
            }
        }
    }, [keycloak]);

    useEffect(() => {
        getDownloadAgentData();
    }, [getDownloadAgentData]);

    return (
        <div className="dashboard-main-container">
            {
                loading ? <LoadingComponent/> : ""
            }

            <div className="container-fluid section-spacing">
                <h1>Software Download</h1>

                <div className="download-agent-main-container section-spacing-top">
                    {
                        clientData.status === 'success' ?
                            <div className="row content-middle download-agent-row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                    <div className="download-agent-image-container">
                                        <GoDownload className="download-agent-image"/>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-5 col-5">
                                    <div className="download-agent-name">
                                        <h5>{clientData.data.Name}</h5>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-5 col-5 text-center">
                                    <div className="download-agent-download">
                                        <a href={clientData.data.Url}
                                           className="btn btn-secondary btn-lg btn-block">
                                            Download Agent
                                        </a>
                                    </div>
                                </div>
                            </div>
                            : <div className="row content-middle download-agent-row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                    <div className="download-agent-image-container">
                                        <GoDownload className="download-agent-image"/>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-5 col-5">
                                    <div className="download-agent-name empty-message">
                                        {clientData.data}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-5 col-5 text-center">
                                    <div className="download-agent-download">
                                        <div className="btn btn-secondary btn-lg btn-block disabled">
                                            Download Agent
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>

                <div className="download-agent-main-container section-spacing-top">
                    <div className="row content-middle download-agent-row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                            <div className="download-agent-image-container">
                                <GoDownload className="download-agent-image"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-5 col-5">
                            <div className="download-agent-name">
                                <h5>Mac</h5>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-5 col-5 text-center">
                            <div className="download-agent-download">
                                <a href="https://slv.vsax.net/download/VSAX.pkg"
                                    className="btn btn-secondary btn-lg btn-block">
                                    Download Agent
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoftwareDownload;