import React, {useState} from 'react';
import {Form, Row, Col, Button, Alert} from 'react-bootstrap';
import './RelatedServices.css';
import SEOMetaTags from "../../components/SEOMetaTags";
import Features from "../solutions/components/Features";

const RelatedServices = () => {
    const apiId = "/related-services/1";

    const [software, setSoftware] = useState('');
    const [device, setDevice] = useState('');
    const [ssd, setSsd] = useState('');
    const [ram, setRam] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleSoftwareChange = (e) => setSoftware(e.target.value);
    const handleDeviceChange = (e) => setDevice(e.target.value);
    const handleSsdChange = (e) => setSsd(e.target.value);
    const handleRamChange = (e) => setRam(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowSuccessMessage(true);

        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 5000); // Show success message for 5 seconds

        // Clear form fields
        setSoftware('');
        setDevice('');
        setSsd('');
        setRam('');
        e.target.reset();
    };

    return (
        <section>
            <SEOMetaTags apiId={apiId} filters=""/>

            <div className="container section-spacing">
                <div className="container-fluid">
                    <Features apiId={apiId} isRelatedServices={true}/>

                    <div className="main-div section-spacing-top">
                        <div className="text-center mb-4 mt-5">
                            <img className="logo-dell"
                                 src="https://myitsupportus.s3.us-west-2.amazonaws.com/Dell_Technologies_logo_black_f9b140f263.svg"
                                 alt="Logo"/>
                        </div>

                        <Form name="relatedServicesForm" onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <h5>Personal/Company Information</h5>
                                    <Form.Group controlId="formName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your name" name="name"/>
                                    </Form.Group>
                                    <Form.Group controlId="formCompany">
                                        <Form.Label>Company</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your company name" name="company"/>
                                    </Form.Group>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email" name="email" required/>
                                    </Form.Group>
                                    <Form.Group controlId="formContactNumber">
                                        <Form.Label>Contact Number</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your contact number"
                                                      name="contactNumber"/>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <h5>Billing & Shipping Address</h5>
                                    <Form.Group controlId="formBillingName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter billing name" name="billingName"/>
                                    </Form.Group>
                                    <Form.Group controlId="formStreet">
                                        <Form.Label>Street</Form.Label>
                                        <Form.Control type="text" placeholder="Enter street address" name="street"/>
                                    </Form.Group>
                                    <Form.Group controlId="formCity">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" placeholder="Enter city" name="city"/>
                                    </Form.Group>
                                    <Form.Group controlId="formState">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control type="text" placeholder="Enter state" name="state"/>
                                    </Form.Group>
                                    <Form.Group controlId="formZip">
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control type="text" placeholder="Enter zip code" name="zip"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md={6}>
                                    <h5 className="mb-2">Software Necessity</h5>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Do you need software?</Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Yes"
                                                value="Yes"
                                                checked={software === 'Yes'}
                                                onChange={handleSoftwareChange}
                                                name="software"
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="No"
                                                value="No"
                                                checked={software === 'No'}
                                                onChange={handleSoftwareChange}
                                                name="software"
                                            />
                                        </div>
                                        <Form.Text className="text-muted">
                                            (e.g., M365, McAfee, Adobe, QuickBooks, etc.)
                                        </Form.Text>
                                    </Form.Group>
                                    <h5>Accessories</h5>
                                    <Form.Group className="mb-4">
                                        <Row>
                                            <Col>
                                                <Form.Check type="checkbox" label="Monitor" value="Monitor"
                                                            name="accessories"/>
                                                <Form.Check type="checkbox" label="Mouse" value="Mouse" name="accessories"/>
                                                <Form.Check type="checkbox" label="Keyboard" value="Keyboard"
                                                            name="accessories"/>
                                                <Form.Check type="checkbox" label="Bag" value="Bag" name="accessories"/>
                                            </Col>
                                            <Col>
                                                <Form.Check type="checkbox" label="Docking Station" value="Docking Station"
                                                            name="accessories"/>
                                                <Form.Check type="checkbox" label="Additional Charger"
                                                            value="Additional Charger"
                                                            name="accessories"/>
                                                <Form.Check type="checkbox" label="Headphones" value="Headphones"
                                                            name="accessories"/>
                                                <Form.Check type="checkbox" label="Camera" value="Camera"
                                                            name="accessories"/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <h5>Devices</h5>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Laptop"
                                            value="Laptop"
                                            checked={device === 'Laptop'}
                                            onChange={handleDeviceChange}
                                            name="device"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Desktop"
                                            value="Desktop"
                                            checked={device === 'Desktop'}
                                            onChange={handleDeviceChange}
                                            name="device"
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Tablet"
                                            value="Tablet"
                                            checked={device === 'Tablet'}
                                            onChange={handleDeviceChange}
                                            name="device"
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Row>
                                            <Col>
                                                <h5>SSD</h5>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="256GB"
                                                    value="256GB"
                                                    checked={ssd === '256GB'}
                                                    onChange={handleSsdChange}
                                                    name="ssd"
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="512GB"
                                                    value="512GB"
                                                    checked={ssd === '512GB'}
                                                    onChange={handleSsdChange}
                                                    name="ssd"
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="1TB"
                                                    value="1TB"
                                                    checked={ssd === '1TB'}
                                                    onChange={handleSsdChange}
                                                    name="ssd"
                                                />
                                            </Col>
                                            <Col>
                                                <h5>RAM</h5>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="8GB"
                                                    value="8GB"
                                                    checked={ram === '8GB'}
                                                    onChange={handleRamChange}
                                                    name="ram"
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="16GB"
                                                    value="16GB"
                                                    checked={ram === '16GB'}
                                                    onChange={handleRamChange}
                                                    name="ram"
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="32GB"
                                                    value="32GB"
                                                    checked={ram === '32GB'}
                                                    onChange={handleRamChange}
                                                    name="ram"
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="text-center">
                                    <div className="my-5">
                                        <img className="logo-center"
                                             src="https://myitsupportus.s3.us-west-2.amazonaws.com/misu_logo_b35f4b13e6.png"
                                             alt="Logo"/>
                                        <p>MiS is a proud member of <br/> <b>Dell Expert Network</b>
                                            <br/>
                                            <br/>
                                            Gain access to partner pricing for your <br/> business needs with the leading
                                            technology <br/> provider in the market.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit" className="mt-4">
                                Submit
                            </Button>
                        </Form>

                        {showSuccessMessage && (
                            <Alert variant="success" className="mt-4 custom-success-message">
                                Thank you! Your information has been received. We will be in touch with you shortly to
                                assist
                                with
                                your account.
                            </Alert>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RelatedServices;
