import './Shop.css';
import FilterCategories from "./components/FilterCategories";
import useFetch from "../../hooks/useFetch";
import ProductCards from "./components/ProductCards";
import LoadingComponent from "../../components/LoadingComponent";
import React from "react";
import ErrorHandling from "../../components/ErrorHandling";
import PropTypes from "prop-types";
import SEOMetaTags from "../../components/SEOMetaTags";

function Shop({slug, heading}) {
    let apiId = "/shop";
    let productsApiId = "/product-cards";

    const queryParameters = new URLSearchParams(window.location.search)
    const category = queryParameters.get("category");
    const price = queryParameters.get("price");
    const priceHeading = getPriceHeading();

    const categoryFilter = category ? "&filters[$and][0][group_category][name][$eqi]=" + category : "";

    const url = process.env.REACT_APP_API_PATH + productsApiId + "/?populate[0]=icon&populate[3]=group_category&populate[4]=menu_dropdown&populate[5]=group_solutions&filters[$and][0][is_active][$eq]=true" + categoryFilter;
    const {response, loading, error} = useFetch(url);

    if (loading) return <LoadingComponent/>;

    return (
        error ? (<ErrorHandling error={error.message}/>) :
            (<section className="shop-section-container">
                <SEOMetaTags apiId={apiId} filters=""/>

                <div className="container">
                    <div className="container-fluid section-spacing">
                        <div className="shop-header-container">
                            <h1>
                                {heading ?? "Check Out Our Products"}
                            </h1>
                        </div>

                        <div className="shop-filters-container content-middle">
                            <div className="ms-0 m-3">
                                Filtering:
                            </div>

                            <FilterCategories apiId={apiId} priceFilter={price} categoryFilter={category} slug={slug}/>

                            <div className="dropdown select-component">
                                <button className="dropdown-toggle select-toggle" type="button" id="priceFilter"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {priceHeading}
                                </button>
                                <div className="dropdown-menu select-menu" aria-labelledby="priceFilter">
                                    <a className="dropdown-item"
                                       href={slug + "?price=desc" + (category ? "&category=" + category : "")}>High
                                        to
                                        Low</a>
                                    <a className="dropdown-item"
                                       href={slug + "?price=asc" + (category ? "&category=" + category : "")}>Low
                                        to
                                        High</a>
                                </div>
                            </div>

                            <div className="clear-filters-container">
                                <a className="clear-filters" href={slug}>Clear Filters</a>
                            </div>
                        </div>

                        <div className="product-cards-container section-spacing">
                            <ProductCards response={response} price={price} category={category}/>
                        </div>
                    </div>
                </div>
            </section>)
    )

    function getPriceHeading() {
        if (price === 'asc') {
            return "Low to High";
        } else if (price === 'desc') {
            return "High to Low";
        }

        return "Price";
    }
}

Shop.propTypes = {
    slug: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired
};

export default Shop;