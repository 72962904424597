import React from "react";

function LoadingComponent() {
    return (
        <section className="default-loading-page section-spacing content-middle">
            <div className="spinner"></div>
        </section>
    )
}

export default LoadingComponent;