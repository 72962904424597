import "./Checkout.css"
import React from "react";
import {verifyIfKeycloakInit} from "../../Keycloak";
import SEOMetaTags from "../../components/SEOMetaTags";

function Confirmation() {
    localStorage.removeItem("products");
    localStorage.removeItem("orderlines");

    const token = verifyIfKeycloakInit();

    return (
        <section>
            <SEOMetaTags apiId={"/shopping-cart"} filters=""/>
            <div className="container">
                <div className="container-fluid">
                    <div className="checkout-confirmation-main-container section-spacing text-center">
                        <div className="checkout-confirmation-heading-container">
                            <h1>
                                Congratulations, Payment Has Been Made
                            </h1>
                        </div>

                        <div className="checkout-confirmation-heading-container mt-5">
                            Your order was completed successfully.

                            <div className="row mt-4">
                                <div className="col-12">
                                    {
                                        token ? (<a href={"/buy-more"}
                                                    className="btn btn-secondary btn-lg btn-block me-2 content-middle">
                                            Continue Shopping
                                        </a>) : (<a href={"/pricing-and-plans"}
                                                    className="btn btn-secondary btn-lg btn-block me-2 content-middle">
                                            Continue Shopping
                                        </a>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Confirmation;