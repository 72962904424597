import Footer from "./footer/Footer";
import Sidebar from "./header/Sidebar";
import React from "react";

const LoggedInLayout = ({children}) => {
    return (
        <>
            <section id="loggedin-layout-container" data-testid="loggedin-layout-container">
                <div className="container-fluid">
                    <div className="row flex-nowrap">
                        <Sidebar/>

                        <div className="col">
                            {children}
                        </div>
                    </div>
                </div>

                <Footer/>
            </section>
        </>
    )
}

export default LoggedInLayout;