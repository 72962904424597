import useFetch from "../../hooks/useFetch";
import "../privacy-policy/Policy.css"
import PolicyAccordion from "../../components/PolicyAccordion";
import React, {useEffect, useMemo, useState} from "react";
import SEOMetaTags from "../../components/SEOMetaTags";

function FAQ() {
    const url = process.env.REACT_APP_API_PATH + "/faqs?sort=id:ASC";
    const {response} = useFetch(url);

    const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([]);

    const memoizedFrequentlyAskedQuestions = useMemo(() => {
        return createResponse(response);
    }, [response]);

    // Update state with memoized data when response changes
    useEffect(() => {
        if (memoizedFrequentlyAskedQuestions) {
            setFrequentlyAskedQuestions(memoizedFrequentlyAskedQuestions.frequentlyAskedQuestions);
        }
    }, [memoizedFrequentlyAskedQuestions]);

    return (
        <section>
            <SEOMetaTags apiId={"/faq-policy"} filters=""/>

            <section className="privacy-policy-main-container">
                <div className="container">
                    <div className="container-fluid section-spacing">
                        <div className="legal-heading-container text-center">
                            <h1>
                                FAQ
                            </h1>
                        </div>

                        {
                            frequentlyAskedQuestions.length > 0 ?
                                <PolicyAccordion policyInformation={frequentlyAskedQuestions}/> :
                                <div className="empty-message text-center">
                                    No frequently asked questions have been created yet.
                                </div>
                        }
                    </div>
                </div>
            </section>
        </section>
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];
            if (data) {
                const frequentlyAskedQuestions = data.map((question) => {
                    const questionAnswer = question.attributes ? question.attributes : [];
                    return {
                        id: question.id,
                        heading: questionAnswer.heading,
                        content: questionAnswer.content,
                    }
                });

                return {frequentlyAskedQuestions};
            }
        }
    }
}

export default FAQ;