import PropTypes from "prop-types";
import useFetch from "../../../hooks/useFetch";
import {useEffect, useMemo, useState} from "react";

function About({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "?populate[0]=AboutUs";
    const {response} = useFetch(url);

    const [aboutUs, setAboutUs] = useState({});

    const memoizedAboutUs = useMemo(() => {
        return createResponse(response);
    }, [response]);

    useEffect(() => {
        if (memoizedAboutUs) {
            setAboutUs(memoizedAboutUs);
        }
    }, [memoizedAboutUs]);

    return (
        aboutUs ? <div className="row">
            <div className="col-12">
                <div className="about-us-heading badge">
                    {aboutUs.heading}
                </div>

                <div className="about-us-description">
                    {aboutUs.description}
                </div>
            </div>
        </div> : null
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];

            if (data) {
                const information = data.attributes ? data.attributes.AboutUs : [];
                return  {
                    id: information.id,
                    heading: information.Heading,
                    description: information.Description,
                }
            }
        }
    }
}

About.propTypes = {
    apiId: PropTypes.string.isRequired
};

export default About;