import useFetch from "../../hooks/useFetch";

function PolicyAndConditionsComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=PoliciesAndConditions";
    const {response} = useFetch(url);

    const policies = [];
    createResponse(response);
    return (
        policies ?
            policies.map((item) => (
                <div className="col-lg-4 col-md-12 col-sm-12 col-12 position-relative" key={item.id}>
                    <div className="information-container py-sm-2 py-md-2">
                        <a className="information-links" href={item.url}>
                            {item.name}
                        </a>
                    </div>
                </div>
            )) : ""
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.PoliciesAndConditions) {
                const policiesAndConditions = data.PoliciesAndConditions;
                policiesAndConditions.forEach(function (policiesAndCondition) {
                    policies.push({
                        id: policiesAndCondition.id,
                        name: policiesAndCondition.name,
                        url: policiesAndCondition.url
                    })
                })
            }
        }
    }
}

export default PolicyAndConditionsComponent;