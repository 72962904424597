import "./Checkout.css"
import {BsXLg} from "react-icons/bs";
import {useNavigate} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from "react";
import {getImageUrl, updateCartCount} from "../../services/util";
import {getKeycloakInstance, verifyIfKeycloakInit} from "../../Keycloak";
import LoadingComponent from "../../components/LoadingComponent";
import {validateCustomerBasket, validateCustomerEmailAddress} from "../../hooks/ClientPortal";
import SEOMetaTags from "../../components/SEOMetaTags";

const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

const debounce = (func, delay) => {
    let timer;
    const debouncedFunc = (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };

    // Adding a cancel method to clear the timeout
    debouncedFunc.cancel = () => {
        clearTimeout(timer);
    };

    return debouncedFunc;
};

function ShoppingCart() {
    const navigate = useNavigate();
    const token = verifyIfKeycloakInit();

    const [orderLines, setOrderLines] = useState([]);
    const [orderSubtotal, setOrderSubtotal] = useState(null);
    const [orderTotal, setOrderTotal] = useState(null);

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    const [loading, setLoading] = useState(false);

    const [showLogin, setShowLogin] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [email, setEmail] = useState('');

    const response = checkoutResponse();

    useEffect(() => {
        let products = localStorage.getItem("products");
        if (products && products.length > 0) {
            setOrderLines(JSON.parse(products));
        }
    }, []);

    useEffect(() => {
        function calculateOrderSummary() {
            let lineTotals = [];

            if (orderLines) {
                orderLines.forEach(orderLine => {
                    lineTotals.push(orderLine.line_total);
                });
            }

            let total = lineTotals.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setOrderSubtotal(total);
            setOrderTotal(total);
        }

        calculateOrderSummary();
    }, [orderLines]);

    const validateCustomerEmailResponse = useCallback(async (email) => {
        if (isValidEmail(email)) {
            setLoading(true);
            try {
                const customerEmailValid = await validateCustomerEmailAddress(email);

                if (customerEmailValid) {
                    setShowLogin(true);
                    setShowCheckout(false);
                } else {
                    setShowCheckout(true);
                    setShowLogin(false);
                }

                setLoading(false);
            } catch (e) {
                setLoading(false);
                setShowCheckout(false);
                setShowLogin(false);
            }
        } else {
            setLoading(false);

            setShowCheckout(false);
            setShowLogin(false);
        }
    }, []);

    useEffect(() => {
        const debouncedFetchData = debounce(validateCustomerEmailResponse, 500);
        if (email) {
            debouncedFetchData(email);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [email, validateCustomerEmailResponse]);

    // Handler for input change
    const handleCustomerEmailCheck = (event) => {
        const emailValue = event.target.value.trim();
        setEmail(emailValue);
    };

    async function handleClick() {
        try {
            const validation = await validateCheckout(response, token);
            if (validation) {
                localStorage.setItem("orderlines", JSON.stringify(response));

                navigate('/payment', {
                    state: {
                        response: {
                            redirect_from_cart: true,
                        },
                    }
                });
            }
        } catch (error) {
            console.error("An error occurred during the checkout process:", error);
        }
    }

    const handleLogin = () => {
        const keycloak = getKeycloakInstance();
        if (!keycloak.authenticated) {
            keycloak.login({redirectUri: window.location.origin + '/auth/shopping-cart'});
            navigate('/auth/shopping-cart');
        }
    };

    function handleQuantityUpdate(updatedProduct) {
        const updatedOrderLines = orderLines.map(product => {
            if (product.product_id === updatedProduct.product_id) {
                let price = validateProductPrice(product, updatedProduct.quantity, product.price);

                return {
                    ...product,
                    quantity: updatedProduct.quantity,
                    price: price,
                    line_total: updatedProduct.quantity * price,
                };
            }
            return product;
        });

        setOrderLines(updatedOrderLines);
        localStorage.setItem("products", JSON.stringify(updatedOrderLines));
    }

    function handleProductDelete(updatedProduct) {
        const updatedOrderLines = orderLines.filter(product => {
            return product.product_id !== updatedProduct.product_id;
        });

        setOrderLines(updatedOrderLines);
        localStorage.setItem("products", JSON.stringify(updatedOrderLines));

        updateCartCount();

        let message = "Product removed from cart.";
        setMessage(message);
        setShowAlert(true)
    }

    function handleAlertClose() {
        setMessage(null);
        setError(null);
        setShowAlert(false);
    }

    return (
        <section>
            <SEOMetaTags apiId={"/shopping-cart"} filters=""/>

            <div className="container">
                {
                    loading ? <LoadingComponent/> : ""
                }
                <div className="container-fluid">
                    <div className="shopping-cart-main-container">
                        <div className="shopping-cart-heading-container text-center section-spacing">
                            <h1>
                                Shopping Cart
                            </h1>
                        </div>

                        <div className="row shopping-cart-items-container section-spacing">
                            <div className="col-12">
                                {showAlert && message && (
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        {message}
                                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                    </div>
                                )}

                                {showAlert && error && (
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        {error}
                                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                    </div>
                                )}
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 col-12 row-spacing">
                                {
                                    orderLines.length > 0 ? orderLines.map((product) => (
                                            <div className="row basket-row-items mb-3 pb-3 border-bottom-1 content-middle"
                                                 key={product.product_id}>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                                    <div className="icon-container">
                                                        <img loading="lazy" alt={product.alt}
                                                             src={getImageUrl(product.icon)}
                                                             className="img-fluid"/>
                                                    </div>
                                                </div>

                                                <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-5 col-sm-12 col-12">
                                                            <div className="name-container">
                                                                {product.product_name}
                                                            </div>

                                                            <div className="category-container">
                                                                Category: <span
                                                                className="category">{product.group_category}</span>
                                                            </div>

                                                            <div className="price-container">
                                                                ${Number(product.price).toFixed(2)}
                                                                <span
                                                                    className="price-indicator">{product.price_per_indicator}</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-md-3 col-sm-6 col-6">
                                                            <div className="quantity-container">
                                                                <div className="name-container">Quantity</div>

                                                                <input type="number"
                                                                       className="form-control checkout-inputs"
                                                                       defaultValue={product.quantity}
                                                                       data-price={product.price}
                                                                       data-id={product.product_id}
                                                                       data-priceindicator={product.price_per_indicator}
                                                                       onChange={(event) => handleQuantityUpdate({
                                                                           ...product,
                                                                           quantity: parseInt(event.target.value)
                                                                       })}
                                                                       placeholder="Quantity" min="1"/>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2 col-md-3 col-sm-4 col-4 text-right">
                                                            <div className="total-price-container"
                                                                 id={"line-total-product-" + product.product_id}>
                                                                ${Number(product.line_total).toFixed(2)}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2 col-md-1 col-sm-2 col-2 text-right">
                                                            <BsXLg className="delete-product"
                                                                   onClick={() => handleProductDelete({product_id: product.product_id})}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : <div className="row">
                                            <div className="col-12 text-center empty-message">
                                                You have not added any products to your shopping cart yet.
                                            </div>
                                        </div>
                                }
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="order-summary-container">
                                    Order Summary
                                </div>

                                {
                                    orderTotal ?
                                        <div className="row mb-3">
                                            <div className="col-lg-6 col-md-8 col-sm-8 col-8">
                                                <div className="summary-heading-container">
                                                    Total Due
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-4 col-sm-4 col-4 text-right">
                                                <div className="summary-price-container">
                                                    ${Number(orderTotal).toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }

                                <div className="row mb-5">
                                    <div className="col-12">
                                        <a href="/pricing-and-plans"
                                           className="btn btn-secondary btn-lg btn-block me-2 content-middle w-100">
                                            Continue Shopping
                                        </a>
                                    </div>
                                </div>

                                {
                                    !token && orderTotal ?
                                        (<div className="row mt-2">
                                            <div className="col-12 mb-2 text-center email-validation">
                                                Please enter your email address to complete your order
                                            </div>

                                            <div className="col-12">
                                                <input type="email" onChange={handleCustomerEmailCheck}
                                                       className="form-control checkout-inputs"
                                                       placeholder="Email Address" value={email}/>
                                            </div>
                                        </div>) : null
                                }

                                <div className="row mt-3">
                                    {
                                        orderTotal && email && showLogin ? (<div className="col-12">
                                            <button
                                                className="btn btn-primary btn-lg btn-block me-2 content-middle w-100"
                                                onClick={handleLogin}>
                                                Login to Checkout
                                            </button>
                                        </div>) : null
                                    }

                                    {
                                        ((orderTotal && email && showCheckout) || token) ? (<div className="col-12">
                                            <button
                                                className="btn btn-primary btn-lg btn-block me-2 content-middle w-100"
                                                onClick={handleClick}>
                                                Checkout
                                            </button>
                                        </div>) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    function validateProductPrice(product, quantity, productPrice) {
        let price = productPrice;
        if (product.price_per_indicator.toLowerCase().includes("hour")) {
            const solutions = product.solutions ?? [];
            solutions.forEach(solution => {
                const solutionAttributes = solution.attributes;

                if (quantity >= solutionAttributes.MinQuantity &&
                    (solutionAttributes.MaxQuantity === null || quantity <= solutionAttributes.MaxQuantity)) {
                    price = solutionAttributes.Price;
                }
            });
        }
        return price;
    }

    function checkoutResponse() {
        if (orderLines) {
            const lineItems = [];
            orderLines.forEach(orderLine => {
                const lineItem = {
                    "strapi_product_id": orderLine.product_id,
                    "name": orderLine.product_name,
                    "unit_amount": orderLine.price,
                    "qty": orderLine.quantity,
                    "sku": orderLine.product_sku,
                    "sku_code": orderLine.product_sku_code,
                    "line_total": orderLine.line_total,
                    "category": orderLine.category,
                    "category_group": orderLine.group_category,
                    "is_subscription_based": orderLine.is_subscription_based,
                    "price_per_indicator": orderLine.price_per_indicator,
                };
                lineItems.push(lineItem);
            });

            return {
                "subtotal": orderSubtotal,
                "total_due": orderTotal,
                "line_items": lineItems
            };
        }

        return null;
    }

    async function validateCheckout(orderLines, token) {
        let message = "Please note in order to purchase support a basic license is required to facilitate support - Please select one of the Individual or Business License options";

        if (token) {
            try {
                const response = await validateCustomerBasket(token);
                if (response && !response.data) {
                    setError(message);
                    setShowAlert(true);
                    return false;
                }

                return true;
            } catch (error) {
                console.error('Error validating customer basket:', error);
                setError('There was an error validating the basket.');
                setShowAlert(true);
                return false;
            }
        } else {
            const hasSubscription = orderLines.line_items.some(item => item.is_subscription_based === true);
            const containsHour = orderLines.line_items.some(item => item.price_per_indicator && item.price_per_indicator.includes('hour'));

            if (!hasSubscription && containsHour) {
                setError(message);
                setShowAlert(true);
                return false;
            }

            return true;
        }
    }
}

export default ShoppingCart;