import React, { useEffect, useState } from 'react';
import PrivateLayout from './templates/PrivateLayout';
import { initKeycloak, getKeycloakInstance } from '../Keycloak';

const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true); // Introduce loading state
  const [isKeycloakInitialized, setIsKeycloakInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const initializeKeycloak = async () => {
      try {
        await initKeycloak();
        const keycloak = getKeycloakInstance();
        setIsKeycloakInitialized(true);
        setIsLoggedIn(keycloak.authenticated);
        
        // Redirect to login if not authenticated
        if (!keycloak.authenticated) {
          keycloak.login();
        }
      } catch (error) {
        console.error('Keycloak initialization failed', error);
      } finally {
        setIsLoading(false); // Set loading state to false after initialization
      }
    };

    initializeKeycloak();
  }, []);

  useEffect(() => {
    const keycloak = getKeycloakInstance();

    // Listen to keycloak events to update isLoggedIn state
    const updateLoginStatus = () => {
      setIsLoggedIn(keycloak.authenticated);
    };

    keycloak.onAuthSuccess = updateLoginStatus;
    keycloak.onAuthError = updateLoginStatus;
    keycloak.onAuthRefreshSuccess = updateLoginStatus;
    keycloak.onAuthRefreshError = updateLoginStatus;

    return () => {
      keycloak.onAuthSuccess = null;
      keycloak.onAuthError = null;
      keycloak.onAuthRefreshSuccess = null;
      keycloak.onAuthRefreshError = null;
    };
  }, []);

  if (isLoading) {
    // Optional loading message or spinner can be added here
    return null;
  }

  if (!isKeycloakInitialized) {
    // Optional handling if keycloak initialization fails
    return null;
  }

  return isLoggedIn ? <PrivateLayout>{children}</PrivateLayout> : null;
};

export default PrivateRoute;
