import React, { useMemo, useState, useEffect } from 'react';
import useFetch from "../../../hooks/useFetch";

function Vendors({ apiId }) {
    const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=Vendors&populate[1]=Vendors.VendorInformationList`;
    const { response } = useFetch(url);

    // State variables for vendorHeading and vendors
    const [vendorHeading, setVendorHeading] = useState(null);
    const [vendors, setVendors] = useState([]);

    // Memoize the processed data using useMemo
    const memoizedVendors = useMemo(() => {
        return createResponse(response);
    }, [response]);

    // Update state with memoized data when response changes
    useEffect(() => {
        if (memoizedVendors) {
            setVendorHeading(memoizedVendors.vendorHeading);
            setVendors(memoizedVendors.vendors);
        }
    }, [memoizedVendors]);

    return (
        <div className="container-fluid">
            <div className="vendor-heading-container">
                <h2>{vendorHeading}</h2>
            </div>

            <div className="vendors-main-container">
                <div className="vendor-main-information-container position-relative">
                    {vendors.map((vendor) => (
                        <div className={`vendor-information-container position-${vendor.position}`} key={vendor.id}>
                            <div className="vendor-title-container">
                                <h4>{vendor.heading}</h4>
                            </div>
                            <div className="vendor-description-container">
                                {vendor.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

function createResponse(response) {
    const vendorsData = response?.data?.attributes?.Vendors || {};
    const vendorHeading = vendorsData?.VendorHeading || null;
    const vendorInformationList = vendorsData?.VendorInformationList || [];

    const vendors = vendorInformationList.map((item) => ({
        id: item.id,
        heading: item.heading,
        description: item.description,
        position: item.order,
    }));

    return { vendorHeading, vendors };
}

export default Vendors;
