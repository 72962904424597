import PropTypes from "prop-types";

export default function NavDropdownComponent({menuItem, categories, groupCategories}) {
    return (
        <li key={"dropdown-menu-" + menuItem.id} className="nav-item dropdown">
            <a className="nav-link py-4 px-3 dropdown-toggle" href={menuItem.slug} role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
                {menuItem.title}
            </a>

            <ul className="dropdown-menu mx-3 border-radius-0">
                {
                    categories.map(category => (
                        category.is_active ?
                            <li key={"category-" + category.id}>
                                <a className="dropdown-item" href={'/solutions/' + category.slug}>
                                    {category.name}
                                </a>
                            </li> : null
                    ))
                }

                {
                    groupCategories.map(group_category => (
                        group_category.is_active ?
                            <li key={"group-category-" + group_category.id}>
                                <a className="dropdown-item" href={'/pricing-and-plans?category=' + group_category.slug}>
                                    {group_category.name}
                                </a>
                            </li> : null
                    ))
                }
            </ul>
        </li>
    )
}

NavDropdownComponent.propTypes = {
    menuItem: PropTypes.object,
    categories: PropTypes.array,
    groupCategories: PropTypes.array,
};