import Header from "./header/Header";
import Footer from "./footer/Footer";
import "./footer/Footer.css"

const Layout = ({children}) => {
    return (
        <>
            <section id="main-layout-container" data-testid="main-layout-container">
                <section>
                    <Header/>
                    {children}
                </section>

                <Footer/>
            </section>
        </>
    )
}

export default Layout;