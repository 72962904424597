import Keycloak from 'keycloak-js';

let keycloak = null;

const initKeycloak = () => {
    keycloak = new Keycloak({
        realm: 'myitsupportus',
        url: process.env.REACT_APP_KEYCLOAK_URL,
        clientId: 'myitsupport-ui',
    });

    return keycloak.init({
        onLoad: 'check-sso', // Check silently if the user is already logged in
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        checkLoginIframe: false
    })
};

const getKeycloakInstance = () => {
    if (!keycloak) {
        throw new Error('Keycloak instance not initialized');
    }
    return keycloak;
};

const verifyIfKeycloakInit = () => {
    if (!keycloak) {
        return null
    } else {
        return keycloak.token
    }
}

export {initKeycloak, getKeycloakInstance, verifyIfKeycloakInit};
