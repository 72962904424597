import PropTypes from "prop-types";
import useFetch from "../../../hooks/useFetch";
import ProductCards from "../../shop/components/ProductCards";
import {useEffect, useState} from "react";

function Pricing({apiId, slug}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "?filters[category][slug][$eq]=" + slug + "&populate[0]=product_cards&populate[1]=product_cards.icon&populate[2]=product_cards.group_category&populate[3]=product_cards.menu_dropdown&populate[4]=product_cards.group_solutions";
    const {response} = useFetch(url);

    const [products, setProducts] = useState(null);

    useEffect(() => {
        if (response && response.data !== undefined) {
            response.data.forEach((product) => {
                const pricingData = product.attributes.product_cards ?? [];
                setProducts(pricingData);
            })
        }
    }, [response]);

    return (
        <div className="container-fluid">
            <div className="information-product-name">
                <h2>
                    Pricing Comparison
                </h2>
            </div>

            <div className="product-cards-container">
                <ProductCards response={products} price={null} category={null}/>
            </div>
        </div>
    )
}

Pricing.propTypes = {
    apiId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
};

export default Pricing;