import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {BsCart} from 'react-icons/bs';
import NavDropdownComponent from './NavDropdownComponent';
import {getKeycloakInstance, initKeycloak} from '../../Keycloak';
import {updateCartCount} from "../../services/util";

export default function NavigationComponent({menuItems, callToActionButtons}) {
    const [isKeycloakInitialized, setIsKeycloakInitialized] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const initializeKeycloak = async () => {
            try {
                await initKeycloak();
                setIsKeycloakInitialized(true);
                const keycloak = getKeycloakInstance();
                const authenticated = keycloak.authenticated;
                setIsLoggedIn(authenticated);
            } catch (error) {
                console.error('Keycloak initialization failed', error);
            }
        };

        initializeKeycloak();
    }, [navigate]);

    useEffect(() => {
        updateCartCount();
    }, []);

    const handleLogin = () => {
        try {
            navigate('/dashboard');
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await getKeycloakInstance().logout({redirectUri: window.location.origin + '/'});
            setIsLoggedIn(false);
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    let location = useLocation();
    let pathName = location.pathname;

    if (!isKeycloakInitialized) {
        // Optional loading or initialization message can be added here
    }

    return (
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
                {menuItems.map((menuItem) => (
                    ((menuItem.categories.length > 0) || (menuItem.group_categories.length > 0)) ? (
                        <NavDropdownComponent key={menuItem.id} menuItem={menuItem} categories={menuItem.categories} groupCategories={menuItem.group_categories} />
                    ) : (
                        menuItem.is_active ? (
                            <li key={"main-menu-" + menuItem.id} className="nav-item">
                                <a
                                    className={"nav-link py-4 px-2" + (pathName === menuItem.slug ? " active" : "")}
                                    aria-current="page"
                                    href={menuItem.slug}
                                >
                                    {menuItem.title}
                                </a>
                            </li>
                        ) : null
                    )
                ))}
            </ul>

            <div className="d-inline-flex ml-auto ps-3 call-to-action-container content-middle">
                <div className="position-relative">
                    <a href={isLoggedIn ? "/auth/shopping-cart" : "/shopping-cart"} id="buy-more" className="shopping-cart-container">
                        <BsCart className="shopping-cart-icon me-4"/>
                    </a>
                </div>

                {callToActionButtons.map((callToAction) => (
                    <a href="/contact-us"
                       key={"call-to-action-" + callToAction.id}
                       className={(callToAction.type === "primary" ? "btn-primary" : "btn-secondary") + " btn btn-lg btn-block me-2 call-to-action"}>
                        {callToAction.title}
                    </a>
                ))}

                {isLoggedIn ? (
                    <div>
                        <a href="/dashboard" className="btn btn-primary btn-lg btn-block me-2 call-to-action">
                            Dashboard
                        </a>
                        <button className="btn btn-secondary btn-lg btn-block me-2 call-to-action"
                                onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                ) : (
                    <button className="btn btn-secondary btn-lg btn-block me-2 call-to-action"
                            onClick={handleLogin}>
                        Login
                    </button>
                )}
            </div>
        </div>
    );
}
