import React, { useMemo, useState, useEffect, useCallback } from 'react';
import useFetch from "../../../hooks/useFetch";
import { getImageUrl } from "../../../services/util";

const Industries = ({ apiId }) => {
  const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=IndustriesHeading&populate[1]=Industries&populate[2]=Industries.image`;
  const { response } = useFetch(url);

  // State variables for industriesHeading and industries
  const [industriesHeading, setIndustriesHeading] = useState(null);
  const [industries, setIndustries] = useState([]);

  // Memoize the processed data using useMemo
  const memoizedIndustries = useMemo(() => {
    return createResponse(response);
  }, [response]);

  // Function to handle caching and fetching data
  const fetchData = useCallback(async () => {
    try {
      const cachedData = localStorage.getItem('industriesData');
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setIndustriesHeading(parsedData.industriesHeading);
        setIndustries(parsedData.industries);
      }
      else
      {
        const response = await fetch(url);
        const data = await response.json();
  
        // Update state with new data
        const newData = createResponse(data);
        setIndustriesHeading(newData.industriesHeading);
        setIndustries(newData.industries);
  
        // Cache the fetched data
        localStorage.setItem('industriesData', JSON.stringify(newData));
      }

    
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error gracefully
    }
  }, [url]);

  // Update state with memoized data when response changes
  useEffect(() => {
    if (memoizedIndustries) {
      setIndustriesHeading(memoizedIndustries.industriesHeading);
      setIndustries(memoizedIndustries.industries);
    }
    fetchData();
  }, [memoizedIndustries, fetchData]);

  return (
    <div className="industries-main-container">
      <div className="industries-heading-container">
        <h2>{industriesHeading}</h2>
      </div>

      <div className="row">
        {industries.map((item) => (
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" key={item.id}>
            <div className="content-middle industries-container">
              <div className="industries-icon-container">
                {item.image && (
                  <img loading="lazy" src={getImageUrl(item.image)} alt={item.alt} />
                )}
              </div>

              <div className="industries-title-container">
                {item.name}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

function createResponse(response) {
  const industriesHeading = response?.data?.attributes?.IndustriesHeading || null;
  const industriesData = response?.data?.attributes?.Industries || [];

  const industries = industriesData.map((industry) => {
    const industryImage = industry.image?.data?.attributes;
    return {
      id: industry.id,
      name: industry.name,
      image: industryImage ? industryImage.url : null,
      alt: industryImage ? industryImage.alternativeText : null,
    };
  });

  return { industriesHeading, industries };
}

export default Industries;
